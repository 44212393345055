import React from 'react';
import {
    BrowserRouter as Router,
    Switch
  } from "react-router-dom";
import AdminRoute from '../Components/AdminRoute';
import AdminClient from './Admin/AdminClient';
import AdminClientDetails from './Admin/AdminClientDetails';
import AdminHome from './Admin/AdminHome';
import AdminVideoPage from './Admin/AdminVideoPage';


const AdminPage = () => {

    return (<>
    <Router>
        <Switch>
            <AdminRoute path="/admin" component={AdminHome} exact />
            <AdminRoute path="/admin/clients" component={AdminClient} exact/>
            <AdminRoute path="/admin/clients/:code" component={AdminClientDetails}/>
            <AdminRoute path="/admin/videos" component={AdminVideoPage} />
        </Switch>
    </Router>
    </>);
};

export default AdminPage;