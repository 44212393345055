import styled from "styled-components";
import ActiveChangeColour from "./ActiveChangeColour";

import { faCircle as faClosedCircle, faAdjust } from "@fortawesome/free-solid-svg-icons";
import { faCircle as faOpenCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

export interface ModuleHistoryProgressIndicatorProps {
    anyStarted: boolean;
    allCompleted: boolean;
}

const ActiveChangeColourWithPointer = styled(ActiveChangeColour)`
    cursor: pointer;
`;

const ModuleHistoryProgressIndicator = ({anyStarted, allCompleted}: ModuleHistoryProgressIndicatorProps) => {

    const [statusMessage, setStatusMessage] = useState("");
    const [icon, setIcon] = useState(faOpenCircle);

    useEffect(() => {
        if (!anyStarted) {
            setStatusMessage("Module not started");
            setIcon(faOpenCircle);
            return;
        }
        if (anyStarted) {
            setStatusMessage("Module in progress");
            setIcon(faAdjust);
        }
        if (allCompleted) {
            setStatusMessage("Module completed");
            setIcon(faClosedCircle);
        }
    }, [anyStarted, allCompleted]);

    return (<ActiveChangeColourWithPointer 
        baseColour={"#AAAAAA"} 
        activeColour={"#14702d"} 
        active={anyStarted} 
        title={statusMessage}
    >
        <FontAwesomeIcon icon={icon} />
    </ActiveChangeColourWithPointer>);
}

export default ModuleHistoryProgressIndicator;