import { ApiResponseStatus } from "../Components/ApiResponseStatus";
import IApiResponse from "../Components/IApiResponse";
import IModuleDetail from "../Components/IModuleDetail";
import { ValueResult } from "../Components/Result";
import config from "../config";
import ModuleExtendedSummary from "../Models/ModuleExtendedSummary";
import AuthenticationService from "./AuthenticationService";

export interface IModuleService {

    getExtendedSummary() : Promise<ValueResult<ModuleExtendedSummary[]|null>>;

    getDetails(moduleId: string) : Promise<ValueResult<IModuleDetail|null>>;
}


export default class ModuleService implements IModuleService {
    private static service:IModuleService | null = null;

    public static getService() : IModuleService {
        if (this.service === null) {
            this.service = new ModuleService();
        }

        return this.service;
    }

    async getExtendedSummary() : Promise<ValueResult<ModuleExtendedSummary[]|null>> {

        try {
            const result = await fetch(`${config.apiHost}/modules?view=extended`, {
                method: "get"
            }).then(r => r.json() as Promise<IApiResponse<ModuleExtendedSummary[]>>);

            if (result.status !== ApiResponseStatus.Success) {
                return ValueResult.Failed<ModuleExtendedSummary[]>(result.error?.map(e => `${e.code}: ${e.message}`) ?? []);
            } else if (result.body === null) {
                return ValueResult.Failed<ModuleExtendedSummary[]>(["Unexpected error: Api returned success with no module details"]);
            }

            return ValueResult.Success(result.body);

        } catch (e) {
            return ValueResult.Failed<ModuleExtendedSummary[]>(e);
        }
    }

    async getDetails(moduleId: string): Promise<ValueResult<IModuleDetail|null>> {
        const tokenResult = await AuthenticationService.getService().getToken();
        if (!tokenResult.isSuccess || tokenResult.value === null) {
            return ValueResult.Failed<IModuleDetail>(tokenResult.messages ?? []);
        }

        try {
            const token = tokenResult.value;
            const result = await fetch(`${config.apiHost}/modules/${moduleId}`, {
                method: "get",
                headers: {"Authorization": `Bearer ${token}`},
            }).then(r => r.json() as Promise<IApiResponse<IModuleDetail>>);

            if (result.status !== ApiResponseStatus.Success) {
                return ValueResult.Failed<IModuleDetail>(result.error?.map(e => `${e.code}: ${e.message}`) ?? []);
            } else if (result.body === null) {
                return ValueResult.Failed<IModuleDetail>(["Unexpected error: Api returned success with no module details"]);
            }

            return ValueResult.Success(result.body);

        } catch (e) {
            return ValueResult.Failed<IModuleDetail>(e);
        }
    }
}