import React from "react";
import styled, { css } from "styled-components";
import { Grid, GridItem } from "./Grid";

const StyledForm = styled.form`
    position: relative;
    width: 100%;
`;

const FormGrid = styled(Grid)`
    grid-template-columns: fit-content(50%) auto;
`;

const StyledFormLabel = styled.label`
    padding: 0.5rem;
    align-self: center;
    justify-self: end;
`;

const StyledFormTextInput = styled.input<{error?: boolean | null}>`
padding: 0.5rem;
margin: 0.1rem;
border-radius: 0.5rem;
width: 100%;
box-sizing: border-box;

/*font-size: 1em;*/
${({error}) => 
    error &&
    css`
        border: 2px solid ${props => props.theme.error.border};
        background-color: ${props => props.theme.error.background}
    `
}
`;

const Form = ({children, ...props} : React.HTMLAttributes<HTMLFormElement>) =>
    <StyledForm {...props}>
        <FormGrid>
            {children}
        </FormGrid>
    </StyledForm>;

const FormLabel = ({children, ...props}: React.HTMLAttributes<HTMLLabelElement>) => 
    <GridItem>
        <StyledFormLabel {...props}>
            {children}
        </StyledFormLabel>
    </GridItem>;

interface FormTextInputProps extends React.HTMLAttributes<HTMLInputElement> {
    error?: boolean | null;
}

const FormTextInput = ({...props}: FormTextInputProps) =>
    <GridItem>
        <StyledFormTextInput {...props} />
    </GridItem>;

export {
    Form,
    FormLabel,
    FormTextInput
};