import config from "../config.js";

import { Result, ValueResult } from "../Components/Result";
import ee, { ServiceProvider } from "@ee/ee-authentication-services";

export enum LoginState {
    ValidLogin,
    NoAppAccess,
    InvalidCredentials,
    UnknownError
}

export interface IAuthenticationService {
    login(email: string, password: string, stayLoggedIn: boolean) : Promise<ValueResult<LoginState|null>>;

    canGetValidToken() : Promise<boolean>;

    grantAppAccess() : Promise<Result>;

    isAdmin() : Promise<Result>;

    getToken() : Promise<ValueResult<string|null>>;
    logout() : Promise<Result>;
}

export default class AuthenticationService implements IAuthenticationService {
    
    authService: ee.IAuthenticationService;
    appService: ee.IApplicationService;
    
    constructor() {
        const sp = new ServiceProvider(config.authHost, config.applicationId);
        this.authService = sp.getAuthenticationService();
        this.appService = sp.getApplicationService();
    }
    
    private static instance : IAuthenticationService|null = null;
    public static getService() : IAuthenticationService {
        if (this.instance === null) {
            this.instance = new AuthenticationService();
        }

        return this.instance;
    }

    async login(email: string, password: string, stayLoggedIn: boolean): Promise<ValueResult<LoginState|null>> {
        try {
            await this.authService.loginUserAsync(email, password);
            return ValueResult.Success<LoginState>(LoginState.ValidLogin);
        }
        catch (e: any) {
            if (e.hasOwnProperty("messages")) {
                if (e.messages[0].startsWith("auth-error")) {
                    return ValueResult.Failed<LoginState>(e.messages, LoginState.InvalidCredentials);
                } else if (e.messages[0].startsWith("no-app-access")) {
                    return ValueResult.Failed<LoginState>(e.messages, LoginState.NoAppAccess);
                } else {
                    return ValueResult.Failed<LoginState>(e.messages, LoginState.UnknownError);
                }
            }
            else {
                return ValueResult.Failed<LoginState>([e.message], LoginState.UnknownError);
            }
        }
    }

    async isAuthenticated() : Promise<boolean> {
        try {
            await this.authService.getAccessTokenAsync();
            return true;
        }
        catch (e) {
            return false;
        }
    }

    async canGetValidToken(): Promise<boolean> {
        const p = await this.getToken();
        return p.isSuccess;
    }
    isAdmin(): Promise<Result> {
        return Promise.resolve(Result.Failed()); // not an admin now
    }
    async getToken(): Promise<ValueResult<string | null>> {
        try {
            var token = await this.authService.getApplicationTokenAsync()
            return ValueResult.Success(token);
        }
        catch (e) {
            return ValueResult.Failed(e);
        }
    }
    async grantAppAccess() : Promise<Result> {
        try {
            await this.appService.grantApplicationSelfAccess();
            return Result.Success();
        } catch (e) {
            return Result.Failed([e.message]);
        }
    }

    async logout(): Promise<Result> {
        try {
            await this.authService.logoutAsync();
            return Result.Success();
        }
        catch (e) {
            return Result.Failed(e);
        }
    }

}


