import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { BreadCrumbs, Crumb } from "../../Components/BreadCrumbs";
import { HorizontalFlexContainer, StandardPage, VerticalFlexContainer } from "../../Components/StyledComponents";
import { Button } from "../../Components/StyledFormInputs";
import { ClientService, IClientDetail } from "../../Services/ClientService";

const ColumnLayout = styled(HorizontalFlexContainer)`
    width: 100%;
`;
const Column = styled(VerticalFlexContainer)`
    flex-grow: 1;
`;

const FullWidthDiv = styled.div`
    width: 100%;
`;

const UnstyledList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 5px;
`;

const Disabled = styled.span`
    color: #AAA;

    &:after {
        content: " (Disabled)"
    }
`;

const RemoveButton = styled.span`
    color: red;
`;

const Remove = () => {
    return (
    <RemoveButton>
        &nbsp;&nbsp;<FontAwesomeIcon icon={faTimes} />
    </RemoveButton>
    );
};

const ClientDetailDisplay = ({client} : {client : IClientDetail}) => {

    const clientService = ClientService.getService();

    const enabledButtonCallback = async (e: any) => {
        e.preventDefault();

        const newState = !client.isEnabled;

        const result = await clientService.setEnabledState(client, newState);

        if (result.isSuccess) {
            // force page reload to get new data
            window.location.reload();
        } else {
            console.log(result.messages);
        }
    };

    return (<FullWidthDiv>
        <h1>Details for {client.name}</h1>    
        <p>
            Status: {client.isEnabled ? "Enabled" : "Disabled"}
            <Button onClick={enabledButtonCallback}>{!client.isEnabled ? "Enable" : "Disable"}</Button>
        </p>
        <p>Access code: {client.accessCode}</p>
        <ColumnLayout>
            <Column>
                <h2>Users</h2>
                <UnstyledList>
                    {client.users.map(u => (
                        <li key={u.identifier}><a href={`/admin/users/${u.identifier}`}>{u.displayName}</a>{!u.isEnabled && <Disabled />}<Remove /></li>
                    ))}
                </UnstyledList>
            </Column>
            <Column>
                <h2>Roles</h2>
                <UnstyledList>
                    {client.roles.map(r => (
                        <li key={r.identifier}><a href={`/admin/roles/${r.identifier}`}>{r.name}</a> {!r.isEnabled && <Disabled />}<Remove /></li>
                    ))}
                </UnstyledList>
            </Column>
        </ColumnLayout>
    </FullWidthDiv>);
}

const AdminClientDetails = () => {
    let { code } = useParams<{code: string}>();

    const clientService = ClientService.getService();

    const [client, setClient] = useState<IClientDetail | null>(null);

    useEffect(() => {
        
        async function getClient() {
            const result = await clientService.getDetail(code);

            if (result.isSuccess && result.value !== null) {
                setClient(result.value);
            } else {
                console.log(result.messages);
            }
        }

        getClient();
    },[clientService, code])

    return (
        <StandardPage flexDirection="column">
            <BreadCrumbs>
                <Crumb label="Admin" href="/admin" />
                <Crumb label="Clients" href="/admin/clients" />
                <Crumb label={client?.name ?? "(Unknown)"} href={`/admin/clients/${client?.code}`} />
            </BreadCrumbs>
            {client == null ? <p>Loading...</p> : <ClientDetailDisplay client={client} />}
        </StandardPage>
    );
}

export default AdminClientDetails;