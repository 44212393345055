import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import HistoryWatchPosition from "../Models/HistoryWatchPosition";
import ModuleExtendedSummary from "../Models/ModuleExtendedSummary";
import VideoSummary from "../Models/VideoSummary";
import FavouriteItem from "./FavouriteItem";
import HistoryProgressItem from "./HistoryProgressItem";
import FullHeightVerticalCentredContainer from "./styled/FullHeightVerticalCentredContainer";
import ModuleHistoryProgressIndicator from "./styled/ModuleHistoryProgressIndicator";

export interface ModuleLibraryPageModuleAccordionProps {
    modules: ModuleExtendedSummary[];
    favourites: VideoSummary[];
    histories: HistoryWatchPosition[];
}

export interface ExtendedVideoSummary extends VideoSummary {
    favourited: boolean;
    history: HistoryWatchPosition | null;
}

export interface ModuleHistorySummary {
    identifier: string;
    label: string;
    sortPosition: number;
    anyStarted: boolean;
    anyCompleted: boolean;
    allCompleted: boolean;
    videos: ExtendedVideoSummary[];
}

const Link = styled.a`
    text-decoration: none;
`;

interface MaybeLinkContentProps extends React.HTMLProps<HTMLDivElement> {
    makeLink: boolean;
    link: string
}

const MaybeLinkContent = ({makeLink, link, children}: MaybeLinkContentProps) => {
    return makeLink 
        ? (<Link href={`${link}`}>{children}</Link>)
        : (<>{children}</>)
    ;
}

const AccordionContainer = styled.div`
    width: 26rem;

    @media (max-width: 750px) {
        display: none;
    }
`;

const AccordionInner = styled.div`
    position: relative;
    width: 100%;
`;


const AccordionItem = styled("div")<{padBottom?: boolean, borderTop?: boolean}>`
    position: relative;
    background-color: white;

    ${({padBottom}) => padBottom !== undefined && padBottom &&
        css`margin-bottom: 0.2rem;`
    }
    ${({borderTop}) => borderTop !== undefined && borderTop &&
        css`border-top: 1px solid black;`
    }

    &:last-child {
        padding-bottom: 0;
    }

`;

const AccordionItemTitleContainer = styled("div")<{colourIndex: number}>`
    position: relative;
    margin: 0;
    padding: 1rem;

    display: grid;
    grid-template-columns: auto 16px;

    cursor: pointer;
    background-color: ${props => props.theme.moduleColours[props.colourIndex % props.theme.moduleColours.length]};
`;

const AccordionItemTitle = styled.h3`
    margin: 0;
    padding: 0;
    color: white;
`;


const AccordionItemSubtitleContainer = styled.div`
    position: relative;
    margin: 0;
    padding: 1rem;
    
    display: grid;
    grid-template-columns: auto 16px 0.3rem 16px;

    &:hover {
        background-color: #EEE;
    }
`;
const AccordionItemSubtitle = styled("h4")<{strikeThrough: boolean}>`
    margin: 0;
    padding: 0;
    cursor: pointer;
    color: black;

    ${({strikeThrough}) => strikeThrough &&
        css`
            color: #696969;
            text-decoration: line-through;
        `
    }
`;

const AccordionItemBody = styled("div")<{active: boolean, bodyHeight: number, noPadHorizontal?: boolean}>`
    display: block;
    position: relative;
    margin: 0;
    padding: 0 1rem;
    overflow: hidden;
    height: 0;
    transition: height 0.3s;

    ${({noPadHorizontal}) => noPadHorizontal !== undefined && noPadHorizontal &&
        css`
        padding-left: 0;
        padding-right: 0;
        `
    }

    ${({active, bodyHeight}) => active &&
        css`
        height: ${bodyHeight}px
        `
    }
`;

const AccordionVideoItem = ({video} : {video: ExtendedVideoSummary}) => {

    const [favourited, setFavourited] = useState(video.favourited);

    const [hasStarted, setHasStarted] = useState(false);
    const [completionCount, setCompletionCount] = useState(0);
    const [maxCompletionCount, setMaxCompletionCount] = useState(3);

    useEffect(() => {
        setFavourited(video.favourited);
    }, [video]);

    useEffect(() => {

        const history = video.history;
        if (history !== null) {
            setHasStarted(history.completionCount > 0 || history.currentPosition > 0);
            setCompletionCount(history.completionCount);
            setMaxCompletionCount(history.maxCompletionCount);
        }

    }, [video.history]);
    return (<AccordionItem borderTop={video.sortPosition > 1}>
        <MaybeLinkContent makeLink={true} link={`/play/${video.identifier}`}>
            <AccordionItemSubtitleContainer>
                <div>
                    <AccordionItemSubtitle strikeThrough={completionCount > 0}>{video.title}{video.durationMinutes > 0 ? ` (${video.durationMinutes} min)` : ""}</AccordionItemSubtitle>
                </div>
                <FavouriteItem active={favourited} setActive={setFavourited} videoIdentifier={video.identifier} />
                <div></div>
                <HistoryProgressItem hasStarted={hasStarted} completionCount={completionCount} maxCompletionCount={maxCompletionCount} />
            </AccordionItemSubtitleContainer>
        </MaybeLinkContent>
    </AccordionItem>);
}

const AccordionModuleItem = ({module, active, setActive}: {module: ModuleHistorySummary, active: boolean, setActive: (state:boolean) => void}) => {
    const bodyRef = useRef<HTMLDivElement>(null);
    const [bodyHeight, setBodyHeight] = useState(0);

    const titleClickCallback = () => {
        if (active) {
            setActive(false);
        } else {
            setActive(true);
            setBodyHeight(bodyRef.current?.scrollHeight ?? 0);
        }
    };

    module.videos.sort((a,b) => a.sortPosition - b.sortPosition);

    return (<AccordionItem  padBottom={true}>
        <AccordionItemTitleContainer onClick={titleClickCallback} colourIndex={(module.sortPosition-1)}>
            <div>
                <AccordionItemTitle>MODULE {module.sortPosition} | {module.label}</AccordionItemTitle>
            </div>
            <FullHeightVerticalCentredContainer>
                <ModuleHistoryProgressIndicator anyStarted={module.anyStarted} allCompleted={module.allCompleted} />
            </FullHeightVerticalCentredContainer>
        </AccordionItemTitleContainer>
        <AccordionItemBody ref={bodyRef} active={active} bodyHeight={bodyHeight} noPadHorizontal={true}>
            {module.videos.map(v => <AccordionVideoItem key={v.identifier} video={v} />)}
        </AccordionItemBody>
    </AccordionItem>);
}

const ModuleLibraryPageModuleAccordion = ({modules, favourites, histories} : ModuleLibraryPageModuleAccordionProps) => {

    const [activeItem, setActiveItem] = useState(-1);
    const [historyModules, setHistoryModules] = useState<ModuleHistorySummary[]>([]);

    const setExpandedWrapper = (index: number) => {

        return (state: boolean) => {
            if (state) {
                setActiveItem(index);
            } else if (activeItem === index) {
                setActiveItem(-1);
            }
        }
    }

    useEffect(() => {

        const favouriteDictionary = favourites.reduce((set, fav) => {
            set.add(fav.identifier);
            return set;
        }, new Set<string>());

        const historyDictionary = histories.reduce((obj, hist) => {
            obj[hist.videoIdentifier] = hist;
            return obj;
        }, {} as any) as {[id: string] : HistoryWatchPosition};

        const updatedModules = modules.map(m => {
            const mVids = m.videos.map(v => {
                const isFavourited = favouriteDictionary.has(v.identifier);
                const hasHistory = historyDictionary.hasOwnProperty(v.identifier);

                return Object.assign({}, v, {
                    favourited: isFavourited,
                    history: hasHistory ? historyDictionary[v.identifier] : null
                }) as ExtendedVideoSummary;
            });

            const anyStarted = mVids.map(v => v.history != null && v.history.currentPosition > 0)
                                        .reduce((l,v) => l || v, false);

            const videoCompletions = mVids.map(v => v.history != null && v.history.completionCount > 0);
            const anyCompleted = videoCompletions.reduce((l,v) => l || v, false);
            const allCompleted = videoCompletions.reduce((l,v) => l && v, true);

            mVids.sort((a,b) => a.sortPosition - b.sortPosition);

            return {
                identifier: m.identifier,
                label: m.label,
                sortPosition: m.sortPosition,
                anyStarted: anyStarted,
                anyCompleted: anyCompleted,
                allCompleted: allCompleted,
                videos: mVids
            } as ModuleHistorySummary;
        });

        updatedModules.sort((a,b) => a.sortPosition - b.sortPosition);

        setHistoryModules(updatedModules);

    }, [modules, favourites, histories]);

    return <AccordionContainer>
        <AccordionInner>
            {historyModules.map((m,i) => <AccordionModuleItem key={m.identifier} 
                                                              module={m} 
                                                              active={i === activeItem}
                                                              setActive={setExpandedWrapper(i)} />)}
        </AccordionInner>
    </AccordionContainer>;
};

export default ModuleLibraryPageModuleAccordion;