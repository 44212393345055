import React, { InputHTMLAttributes, useState } from 'react';
import styled, { css } from 'styled-components';
import { Title } from './StyledComponents';

export type HtmlFocusEvent = React.FocusEvent<HTMLInputElement>;

const Form = styled.form`
    position: relative;
    max-width: 500px;
    
    display: flex;
    flex-direction: column;

    margin: 2em auto;
    padding: 1.5em;
    font-size: 1.1em;
`;

const FormTitle = styled(Title)`
    color: ${props => props.theme.main.dark};
`;

const InputDisplay = styled.div`
display: flex;
flex-direction: column;
`;

const InputGroup = styled.div`

display: flex;
flex-direction: row;
justify-content: right;


@media only screen and (max-width: 350px) {
    flex-direction: column;
    justify-content: left;
}

width: 100%;
margin: 5px;
padding: 0;
`;

const VerticalGroup = styled.div`
display: flex;
flex-direction: column;
`;

const RightInputGroup = styled(InputGroup) `
margin-left: auto;
`;

const Label = styled.label`
min-width: 5em;
text-align: right;
padding-right: 0.5em;

@media only screen and (max-width: 350px) {
    min-width: 0;
    text-align: left;
}

`;

const InputLabel = styled(Label)`
    width: 130px;
`;

const TextInput = styled.input<{error: boolean | null}>`
padding: 0.5em;
border-radius: 0.5em;
display: inline-flex;

flex-shrink: 100;
flex-grow: 100;

font-size: 1em;
${({error}) => 
    error &&
    css`
        border: 2px solid ${props => props.theme.error.border};
        background-color: ${props => props.theme.error.background}
    `
}
`;

const ErrorLabel = styled(Label)`
color: ${props => props.theme.error.font};
`;

const ErrorListDisplay = styled.ul`
    display: flex;
    flex-direction:column;
    align-items: right;
    margin-left: auto;
    font-size: 0.9em;
    color: ${props => props.theme.error.font}
`;
const ErrorListItem = styled.li`
    display: flex;
    text-align: right;
    margin-left: auto;
`;

const FlexPadding = styled.span`
display: flex;
flex-grow: 100;
`;

const Button = styled.button`
min-width: 5em;
margin: 0.5em;
padding: 0.2em;
font-size: 1.1em;
background-color: ${props => props.theme.main.dark};
color: white;
`;

const FullWidthButton = styled(Button)`
width: 100%;
`;


export interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    errorLabel?: string;
    handler: (event: HtmlFocusEvent) => void;
    validator: (event: HtmlFocusEvent) => boolean;
}

const InputField = ( 
                    {label, handler, validator, errorLabel, ...rest} : InputFieldProps
) => {

    const [error, setError] = useState(false);

    const handleChange = (event: HtmlFocusEvent) => {
        handler(event);
        setError(validator(event));
    };

    return (
        <InputDisplay>
            <InputGroup>
                <VerticalGroup>
                    <FlexPadding />
                    <InputLabel>{label}</InputLabel>
                    <FlexPadding />
                </VerticalGroup>
                <TextInput {...rest} error={error} onBlur={handleChange} onChange={handleChange}></TextInput>
            </InputGroup>
            {(
                error && <ErrorLabel>{errorLabel}</ErrorLabel>
            )}
        </InputDisplay>
    );
}

export interface ErrorListProps {
    items: string[];
}

const ErrorList = ({items}: ErrorListProps) => {
    console.log(items);
    return (
        <ErrorListDisplay>
            {items.map(v => <ErrorListItem key={v}>{v}</ErrorListItem>)}
        </ErrorListDisplay>
    );
};

const Checkbox = styled.input.attrs({type: "checkbox"})``;

export {
    Checkbox,
    Form,
    FormTitle,
    InputDisplay,
    InputGroup,
    VerticalGroup,
    RightInputGroup,
    Label,
    InputLabel,
    InputField,
    ErrorLabel,
    ErrorListDisplay,
    ErrorListItem,
    ErrorList,
    TextInput,
    FlexPadding,
    Button,
    FullWidthButton
};