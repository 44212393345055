import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";
import ActiveChangeColour from "./styled/ActiveChangeColour";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import FavouriteService from "../Services/FavouriteService";

export interface IFavouriteItemProps {
    active: boolean;
    videoIdentifier: string;
    setActive: (state: boolean) => void;
}

const ActiveChangeColourWithPointer = styled(ActiveChangeColour)`
    cursor: pointer;
`;

const FavouriteItem = ({active, setActive, videoIdentifier}: IFavouriteItemProps) => {

    const toggleState = async () => {
        const newFavouriteState = !active;
        const fs = FavouriteService.getInstance();
        if (newFavouriteState) {
            const result = await fs.addToFavourites(videoIdentifier);
            if (!result.isSuccess) {
                console.log("could not add favourite");
                console.log(result.messages);
            }
        } else {
            const result = await fs.removeFromFavourites(videoIdentifier);
            if (!result.isSuccess) {
                console.log("could not remove favourite");
                console.log(result.messages);
            }
        }

        setActive(!active);
    };

    return (<ActiveChangeColourWithPointer 
        baseColour={"#AAAAAA"} 
        activeColour={"#ffe414"} 
        active={active} 
        title={"Favourite"}
        onClick={() => toggleState()}
    >
        <FontAwesomeIcon icon={active ? faStar : farStar } />
    </ActiveChangeColourWithPointer>);
}

export default FavouriteItem;