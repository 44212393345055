import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, ErrorList, FlexPadding, Form, FormTitle, HtmlFocusEvent, InputField, InputGroup, Label } from '../Components/StyledFormInputs';
import UserService from '../Services/UserService';

const StyledForgotPage = styled(Form)``;

export interface IForgotPasswordPageProps {

}

const ForgotPasswordPage = (props: IForgotPasswordPageProps) => {

    const [email, setEmail] = useState("");

    const [resetStatus, setResetStatus] = useState<boolean|null>(null);
    const [resetErrors, setResetErrors] = useState<string[]>([]);

    const resetCallback = React.useCallback((e) => {
        e.preventDefault();

        async function forgot(email: string) {
            const forgotResult = await UserService.getService().ForgotPassword(email);

            if (forgotResult.isSuccess) {
                setResetStatus(true);
            } else {
                setResetStatus(false);
                setResetErrors(forgotResult.messages);
            }
        }

        forgot(email);

        return false;
    }, [email]);

    return (
        <StyledForgotPage>
            <FormTitle>Forgot password</FormTitle>
            <InputField label="Email" 
                handler={(e : HtmlFocusEvent) => setEmail(e.target.value)} 
                validator={(e: HtmlFocusEvent) => e.target.value === ""} 
                errorLabel="Email address must be provided."/>
            <InputGroup>
                { resetStatus === false && <ErrorList items={resetErrors} />}
                { resetStatus === true && <Label>Please check your email for a password reset link.</Label>}
                </InputGroup>
            <InputGroup>
                <FlexPadding />
                <Button onClick={resetCallback}>Reset</Button>
            </InputGroup>
        </StyledForgotPage>
    );
}

export default ForgotPasswordPage;