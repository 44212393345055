export interface Theme {
    background: string;
    border: string;
    font: string;
    dark: string;
    mid: string;
    light: string;
}

export interface ThemeStyle {
    main: Theme;
    error: Theme;
    moduleColours: string[];
}

const theme : ThemeStyle = {
    main: {
        background: "#E1EEF9",
        border: "#6C16ED",
        font: "#6C16ED",
        dark: "#0C2340",
        mid: "#0077C8",
        light: "#41B6E6"
    },
    error: {
        background: "#FFF3E1",
        border: "#ED6C16",
        font: "#ED6C16",
        dark: "#0C2340",
        mid: "#0077C8",
        light: "#41B6E6"
    },
    moduleColours: ["#0077C8", "#40C1AC", "#B83A4B", "#003A70"]
};

export default theme;