import React from "react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { StandardPage } from "../Components/StyledComponents";
import { ErrorList } from "../Components/StyledFormInputs";
import UserService from "../Services/UserService";

export interface IActivatePageProps {
}

const ActivatePage = (props : IActivatePageProps) => {

    const userService = UserService.getService();

    let { code } = useParams<{code: string}>();

    const [isActivated, setIsActivated] = useState<boolean|null>(null);
    const [activationErrors, setActivationErrors] = useState<string[]>([]);

    useEffect(() => {
        
        async function activate(code: string) {
            var activateResult = await userService.Activate(code);

            if (activateResult.isSuccess) {
                setIsActivated(true);
            } else {
                setIsActivated(false);
                setActivationErrors(activateResult.messages);
            }
            
        };

        activate(code);
        
    }, [code, userService]);

    return (
        <StandardPage>
            {isActivated === null && <p>Activating user...</p>}
            {isActivated === false && <ErrorList items={activationErrors} />}
            {isActivated === true && <p>Your account has been activated. You can now <Link to="/login">login</Link> to access training materials.</p>}
        </StandardPage>
    );

};

export default ActivatePage;