import { useEffect, useState } from "react";
import styled from "styled-components";
import HomepageModuleAccordion from "../Components/HomepageModuleAccordion";
import ExplicitVerticalPadding from "../Components/styled/ExplicitVerticalPadding";
import { BackgroundStandardPage, CentredDiv, Title } from "../Components/StyledComponents";
import ModuleExtendedSummary from "../Models/ModuleExtendedSummary";
import ModuleService from "../Services/ModuleService";

const ContentTitle = styled(Title)`
    color: ${props => props.theme.main.dark};
`;

const ContentPage = () => {

    const [moduleSummaries, setModuleSummaries] = useState<ModuleExtendedSummary[]>([]);

    useEffect(() => {
        const callback = async() => {
            const summariesResult = await ModuleService.getService().getExtendedSummary();

            if (summariesResult.isSuccess) {
                const summaries = summariesResult.value;
                if (summaries !== null) {
                    setModuleSummaries(summaries);
                }
            }
        };

        callback(); // run async
    }, [])

    return (<BackgroundStandardPage colour={"background"}>
        <CentredDiv centreSize={"60%"}>
            <ContentTitle>Course Content</ContentTitle>
            <ExplicitVerticalPadding height={"1rem"} />
            {moduleSummaries.length > 0 && <HomepageModuleAccordion modules={moduleSummaries} />}
        </CentredDiv>
        <ExplicitVerticalPadding height={"1rem"} />
    </BackgroundStandardPage>);
}

export default ContentPage;