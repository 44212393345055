import AcceptableUsePolicy from "../Components/AcceptableUsePolicy";
import DarkTitle from "../Components/styled/DarkTitle";
import { BackgroundStandardPage, CentredDiv } from "../Components/StyledComponents";
import TermsOfService from "../Components/TermsOfService";


const TermsPage = () => {
    return (
        <BackgroundStandardPage colour={"background"}>
            <CentredDiv centreSize={"70%"}>
                <DarkTitle>Terms of Service</DarkTitle>
                <CentredDiv centreSize={"90%"}>
                    <TermsOfService />
                </CentredDiv>
            </CentredDiv>
            <CentredDiv centreSize={"70%"}>
                <DarkTitle>Acceptable Use Policy</DarkTitle>
                <CentredDiv centreSize={"90%"}>
                    <AcceptableUsePolicy />
                </CentredDiv>
            </CentredDiv>
    </BackgroundStandardPage>);
}

export default TermsPage;