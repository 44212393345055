import  React, { useEffect, useState } from  "react";
import { Route, Redirect } from  "react-router-dom";

import authService from "../Services/AuthenticationService";

const PrivateRoute : React.FC<{
    component: React.FC;
    path: string;
    exact: boolean
}> = (props) => {

    const [condition, setCondition] = useState<boolean|null>(null);
    
    useEffect(() => {
        const a = authService.getService();
        a.canGetValidToken()
            .then(v => {
                setCondition(v);
            });
    }, [])

    return  condition === null ? (null) : (
                condition
                ? (<Route  path={props.path}  exact={props.exact} component={props.component} />)
                : (<Redirect  to={`/login?return=${window.location.pathname}`}  />)
            );
}

export default PrivateRoute;