import { ApiResponseStatus } from "../Components/ApiResponseStatus";
import IApiResponse from "../Components/IApiResponse";
import { Result, ValueResult } from "../Components/Result";
import config from "../config";
import HistoryWatchPosition from "../Models/HistoryWatchPosition";
import AuthenticationService from "./AuthenticationService";

export interface IHistoryService {
    
    getAllHistories() : Promise<ValueResult<HistoryWatchPosition[]|null>>;

    getCurrentWatchPosition(videoIdentifier: string) : Promise<ValueResult<HistoryWatchPosition|null>>

    updateWatchInformation(videoIdentifier: string, watchTime: Date, currentPosition: number, completionCount: number, totalWatchTime: number) : Promise<Result>;
}


export default class HistoryService implements IHistoryService {

    private static instance : IHistoryService|null = null;
    public static getService() : IHistoryService {
        if (this.instance === null) {
            this.instance = new HistoryService();
        }

        return this.instance;
    }
    
    async getAllHistories() : Promise<ValueResult<HistoryWatchPosition[]|null>> {
        const tokenResult = await AuthenticationService.getService().getToken();
        if (!tokenResult.isSuccess || tokenResult.value === null) {
            return ValueResult.Failed<HistoryWatchPosition[]>(tokenResult.messages ?? []);
        }

        try {
            const token = tokenResult.value;
            const result = await fetch(`${config.apiHost}/history`, {
                method: "get",
                headers: {"Authorization": `Bearer ${token}`},
            }).then(r => r.json() as Promise<IApiResponse<HistoryWatchPosition[]>>);

            if (result.status !== ApiResponseStatus.Success) {
                return ValueResult.Failed<HistoryWatchPosition[]>(result.error?.map(e => `${e.code}: ${e.message}`) ?? []);
            } else if (result.body === null) {
                return ValueResult.Failed<HistoryWatchPosition[]>(["Unexpected error: Api returned success with no history details"]);
            }

            return ValueResult.Success(result.body);

        } catch (e) {
            return ValueResult.Failed<HistoryWatchPosition[]>(e);
        }
    }

    async getCurrentWatchPosition(videoIdentifier: string): Promise<ValueResult<HistoryWatchPosition|null>> {

        const tokenResult = await AuthenticationService.getService().getToken();
        if (!tokenResult.isSuccess || tokenResult.value === null) {
            return ValueResult.Failed<HistoryWatchPosition>(tokenResult.messages ?? []);
        }

        try {
            const token = tokenResult.value;
            const result = await fetch(`${config.apiHost}/history/${videoIdentifier}`, {
                method: "get",
                headers: {"Authorization": `Bearer ${token}`},
            }).then(r => r.json() as Promise<IApiResponse<HistoryWatchPosition>>);

            if (result.status !== ApiResponseStatus.Success) {
                return ValueResult.Failed<HistoryWatchPosition>(result.error?.map(e => `${e.code}: ${e.message}`) ?? []);
            } else if (result.body === null) {
                return ValueResult.Failed<HistoryWatchPosition>(["Unexpected error: Api returned success with no history details"]);
            }

            return ValueResult.Success(result.body);

        } catch (e) {
            return ValueResult.Failed<HistoryWatchPosition>(e);
        }

    }

    static lastUpdateDetails: {videoIdentifier: string, watchTime: Date, currentPosition: number, completionCount: number, totalWatchTime: number} | null = null;
    
    async updateWatchInformation(videoIdentifier: string, watchTime: Date, currentPosition: number, completionCount: number, totalWatchTime: number): Promise<Result> {
        
        if (HistoryService.lastUpdateDetails !== null) {
            const last = HistoryService.lastUpdateDetails;
            if (last.videoIdentifier === videoIdentifier 
                && last.watchTime === watchTime 
                && last.currentPosition === currentPosition 
                && last.completionCount === completionCount 
                && last.totalWatchTime === totalWatchTime) {
                    // this is identical to the previous request, so we are just going to flat out ignore it
                    console.log("Not pushing history as unchanged");
                    return Result.Success();
            }
        }

        // update the details with the provided details
        HistoryService.lastUpdateDetails = {
            videoIdentifier: videoIdentifier,
            watchTime: watchTime,
            currentPosition: currentPosition,
            completionCount: completionCount,
            totalWatchTime: totalWatchTime
        };

        const tokenResult = await AuthenticationService.getService().getToken();
        if (!tokenResult.isSuccess || tokenResult.value === null) {
            return Result.Failed(tokenResult.messages ?? []);
        }
        
        try {
            const token = tokenResult.value;

            const data = {
                sessionWatchTime: watchTime,
                currentPosition: currentPosition,
                completionCount: completionCount,
                totalSessionWatchDuration: totalWatchTime
            };

            const result = await fetch(`${config.apiHost}/history/${videoIdentifier}`, {
                method: "put",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then(r => r.json() as Promise<IApiResponse<void>>);

            if (result.status !== ApiResponseStatus.Success) {
                return Result.Failed(result.error?.map(e => `${e.code}: ${e.message}`) ?? []);
            } 

            return Result.Success();

        } catch (e) {
            return Result.Failed(e);
        }
    }

}