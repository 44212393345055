
export class Result {

    private constructor(isSuccess: boolean, messages: string[] = []) {
        this.isSuccess = isSuccess;
        this.messages = messages;
    }

    public isSuccess: boolean;

    public messages: string[];

    public static Success() : Result {
        return new Result(true, []);
    }

    public static Failed(messages: string[] = []) : Result {
        return new Result(false, messages);
    }

    public AddValue<U>(value: U) : ValueResult<U> {
        if (this.isSuccess) {
            return ValueResult.Success(value);
        } else {
            throw new Error("Cannot add value to failed result");
        }
    }
}

export class ValueResult<T> {

    private constructor(isSuccess: boolean, messages: string[] = [], value: T | null = null) {
        this.isSuccess = isSuccess;
        this.messages = messages;
        this.value = value;
    }

    public isSuccess: boolean;

    public messages: string[];

    public value : T | null;

    public static Success<U>(value: U) : ValueResult<U> {
        return new ValueResult<U>(true, [], value);
    }

    public static Failed<U>(messages: string[] = [], value: U|null = null) : ValueResult<U|null> {
        return new ValueResult(false, messages, value);
    }

    public AsResult() : Result {
        if (this.isSuccess) {
            return Result.Success();
        } else {
            return Result.Failed(this.messages);
        }
    }
}
