import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import HistoryService from "../Services/HistoryService";
import ISupplementaryMaterial from "./ISupplementaryMaterial";
import IVideoDetail from "./IVideoDetail";
import FlexCentredDiv from "./styled/FlexCentredDiv";
import { FlexPadding, HorizontalFlexContainer, Title, VerticalFlexContainer } from "./StyledComponents";
import ExplicitHorizontalPadding from "./styled/ExplicitHorizontalPadding";
import FavouriteItem from "./FavouriteItem";
import HistoryProgressItem from "./HistoryProgressItem";

interface IVideoSectionProps {
    video: IVideoDetail;
}

const VideoTitle = styled(Title)`
    color: ${props => props.theme.main.mid};
    font-weight: bold;
    text-transform: uppercase;
`;

const Subtitle = styled.h2`
    color: ${props => props.theme.main.mid};
    font-weight: bold;
    text-transform: uppercase;
`;

const Paragraph = styled.p`
    color: ${props => props.theme.main.dark};
`;

const Summary = styled(Paragraph)`
    margin: 1em auto 1rem auto;
    width: 70%;
    text-align: center;

    @media (max-width: 700px) {
        width: 100%;
    }
`;

const MaintainAspectRatio = styled.div`
    position: relative;
    max-height: 90vh;
    max-width: 70vw;

    height: calc(70vw*9/16); 
    width: calc(90vh*16/9);
`;

const VideoPlayer = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    frameborder: 0;
`;

const StyledSupplementaryMaterialsTable = styled.table`
    margin: 1em;
`;

const StyledSupplementaryMaterialsBody = styled.tbody``;

const StyledSupplementaryMaterialRow = styled.tr``;

const TableCell = styled.td`
    padding: 1em;
    padding-top: 0;
`;

const Bold = styled.span`font-weight: bold;`;
const Link = styled.a`color: ${props => props.theme.main.dark}`;

const BigFontsizeSpan = styled.span`
    font-size: 1.5em;
`;

const SupplementaryMaterialRow = ({item}: {item: ISupplementaryMaterial}) => {
    return (<StyledSupplementaryMaterialRow>
        <TableCell><Bold>{item.title}</Bold></TableCell>
        <TableCell><Link href={`${item.accessUrl}`} target="_blank" rel="noreferrer">Download</Link></TableCell>
    </StyledSupplementaryMaterialRow>)
}

const SupplementaryMaterialsSection = ({items}: {items: ISupplementaryMaterial[]}) => {
    return (<div>
        <Subtitle>Supplementary materials</Subtitle>
        {items.length > 0 
            ? <StyledSupplementaryMaterialsTable>
                <StyledSupplementaryMaterialsBody>
                    {items.map(m => <SupplementaryMaterialRow item={m} />)}
                </StyledSupplementaryMaterialsBody>
            </StyledSupplementaryMaterialsTable>
            : <Paragraph>No supplementary materials available.</Paragraph>
        }
        
    </div>
    );
};

interface IPlaybackDetails {
    watchTime: Date;
    watchDuration: number;
    currentPosition: number;
    completionCount: number;
    maxCompletionCount: number;
}

export default function VideoSection({video} : IVideoSectionProps) {

    const updateDurationInSeconds:number = 10;

    const playerRef = useRef<HTMLIFrameElement>(null);

    const playbackRef = useRef<IPlaybackDetails>({ currentPosition: 0, completionCount: 0, maxCompletionCount: 3, watchTime: new Date(), watchDuration: 0});

    const [videoUrl, setVideoUrl] = useState<string|null>(null);   

    const [favourited, setFavourited] = useState(video.hasFavourited);

    const toggleFavouriteState = async (newFavouriteState:boolean) => {
        setFavourited(newFavouriteState);
    };
    
    const [hasPlayedThisSession, setHasPlayedThisSession] = useState(false);
    const [hasCompletedThisSession, setHasCompletedThisSession] = useState(false);

    const [hasStarted, setHasStarted] = useState(false);
    const [completionCount, setCompletionCount] = useState(0);
    const [maxCompletionCount, setMaxCompletionCount] = useState(3);
    
    useEffect(() => {

        let timerInterval: number = -1;

        const isCompleted = (data: any) => data.seconds > (data.duration - 5.0 - updateDurationInSeconds) && !hasCompletedThisSession;

        const performCompletionCheck = (data: any) => {
            // subtraction of 5 seconds is due to a 5 second endcard marks auto completion time
            if (playbackRef.current.watchDuration > 5 + updateDurationInSeconds && isCompleted(data)) {
                // we are at the end, so trigger as a completion
                setHasCompletedThisSession(true); // only do this once
            }
        }

        const postMsg = (id: string) => {
            var iframe = playerRef.current;
            var cW;
            if(iframe) {
                cW = iframe.contentWindow;
            }
            if(!cW) {
              setTimeout(function(){postMsg(id)}, 200);
              return;
            }
            cW.postMessage(JSON.stringify({method:"addEventListener", value: 'playProgress'}), 'https://player.vimeo.com');
            cW.postMessage(JSON.stringify({method:"addEventListener", value: 'play'}), 'https://player.vimeo.com');
            cW.postMessage(JSON.stringify({method:"addEventListener", value: 'pause'}), 'https://player.vimeo.com');
            cW.postMessage(JSON.stringify({method:"addEventListener", value: 'seeking'}), 'https://player.vimeo.com');
        }

        const startPlayback = (data: any) => {

            if (timerInterval > 0) {
                // don't need to restart
                return;
            }

            console.log("Starting");

            setHasPlayedThisSession(true); // always do this
            
            // trigger updates every minute
            timerInterval = window.setInterval(updateElapsedTime, updateDurationInSeconds*1000);
        };
    
        const pauseTimer = () => {
            
            // only run the pause logic if the timer is set
            if (timerInterval !== undefined && timerInterval > 0) {
                console.log("Stopping");
                window.clearInterval(timerInterval);
                timerInterval = -1;

                updateElapsedTime(); // force a trigger of elapsed time callback
                
            }
        };

        const onPlayProgress = (data: any) => {

            const elapsedDuration = Math.max(0,data.seconds - playbackRef.current.currentPosition);

            playbackRef.current.currentPosition = data.seconds;
            playbackRef.current.watchDuration += elapsedDuration;

            performCompletionCheck(data);
        }

        const onSeeking = (data: any) => {
            playbackRef.current.currentPosition = data.seconds;
        };
        
        const messageListener = (e: any) => {
            e.preventDefault();
            
            if (!(/^https?:\/\/player.vimeo.com/).test(e.origin)) {
                return false;
            }
            var evt = JSON.parse(e.data);

            if(evt.event==='playProgress') {
                onPlayProgress(evt.data);
            }
            else if(evt.event === 'seeking') {
                onSeeking(evt.data);
            }
            else if(evt.event==='ready') {
                postMsg(evt.player_id);
            }
            else if(evt.event === 'play') {
                startPlayback(evt.data);            
            }
            else if(evt.event === 'pause') {
                onPlayProgress(evt.data);
                pauseTimer();
            }
        };

        const updateElapsedTime = async () => {

            const result = await HistoryService.getService().updateWatchInformation(video.identifier, 
                                    playbackRef.current.watchTime,
                                    playbackRef.current.currentPosition,
                                    playbackRef.current.completionCount,
                                    playbackRef.current.watchDuration);
    
            setHasStarted(playbackRef.current.currentPosition > 0);
            setCompletionCount(playbackRef.current.completionCount);
            setMaxCompletionCount(playbackRef.current.maxCompletionCount);
    
            if (!result.isSuccess) {
                console.log(`Failed to update: ${JSON.stringify(playbackRef.current)}`);
            }
        };

        window.addEventListener("message", messageListener);
    }, [video.identifier, hasPlayedThisSession, hasCompletedThisSession]);

    useEffect(() => {
        
        HistoryService.getService().getCurrentWatchPosition(video.identifier)
            .then(r => {
                if (r.isSuccess) {
                    playbackRef.current.currentPosition = r.value?.currentPosition ?? 0;
                    playbackRef.current.completionCount = r.value?.completionCount ?? 0;
                    playbackRef.current.maxCompletionCount = r.value?.maxCompletionCount ?? 0;

                    setVideoUrl(`${video.accessUrl}#t=${playbackRef.current.currentPosition}s`);
                    setHasStarted(playbackRef.current.currentPosition > 0);
                    setCompletionCount(playbackRef.current.completionCount);
                    setMaxCompletionCount(playbackRef.current.maxCompletionCount);
                }
            });
    },[video.accessUrl, video.identifier]);

    useEffect(() => {
        if (hasCompletedThisSession) {
            console.log("Marking completed this session");
            playbackRef.current.completionCount++; 
            setCompletionCount(playbackRef.current.completionCount);
        }
    },[hasCompletedThisSession]);

    return (
        <>
            <HorizontalFlexContainer>
                <FlexPadding />
                <VideoTitle>{video.title}</VideoTitle>
                <FlexPadding />
                <VerticalFlexContainer>
                    <FlexPadding />
                    <BigFontsizeSpan>
                        <FavouriteItem active={favourited} setActive={toggleFavouriteState} videoIdentifier={video.identifier}/>
                        <ExplicitHorizontalPadding width={"0.2em"}/>
                        <HistoryProgressItem hasStarted={hasStarted} completionCount={completionCount} maxCompletionCount={maxCompletionCount} />
                    </BigFontsizeSpan>
                    <FlexPadding />
                </VerticalFlexContainer>
            </HorizontalFlexContainer>
            <Summary>{video.summary}</Summary>

            <FlexCentredDiv>
                <MaintainAspectRatio>
                    {videoUrl && <VideoPlayer ref={playerRef} src={videoUrl} allow="autoplay; fullscreen" />}
                </MaintainAspectRatio>
            </FlexCentredDiv>

            <SupplementaryMaterialsSection items={video.supplementaryMaterials} />
            
        </>
    )
}