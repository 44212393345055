import React, { useRef } from "react";
import styled from "styled-components";
import IVideoDetail from "./IVideoDetail";
import FlexCentredDiv from "./styled/FlexCentredDiv";
import { CentredDiv, Title } from "./StyledComponents";

interface IVideoSectionProps {
    video: IVideoDetail;
}

const Summary = styled.p`
    color: white;
    margin: 1em auto 1rem auto;
    width: 70%;
    text-align: center;

    @media (max-width: 700px) {
        width: 100%;
    }
`;

const MaintainAspectRatio = styled.div`
    position: relative;
    max-height: 90vh;
    max-width: 70vw;

    height: calc(70vw*9/16); 
    width: calc(90vh*16/9);
`;

const VideoPlayer = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    frameborder: 0;
`;


export default function AnonymousVideoSection({video} : IVideoSectionProps) {

    const playerRef = useRef<HTMLIFrameElement>(null);

    return (
        <div>
            <Title>{video.title}</Title>
            <Summary>{video.summary}</Summary>

            <CentredDiv centreSize={"90%"}>
                <FlexCentredDiv>
                    <MaintainAspectRatio>
                        <VideoPlayer ref={playerRef} src={video.accessUrl} allow="autoplay; fullscreen" />
                    </MaintainAspectRatio>
                </FlexCentredDiv>
            </CentredDiv>
        </div>
    )
}