import styled, { css } from "styled-components";

export interface ActiveChangeColourProps {
    baseColour: string;
    activeColour: string;
    active: boolean;
}

const ActiveChangeColour = styled("span")<ActiveChangeColourProps>`
    color: ${props => props.baseColour};

    ${props => props.active &&
        css`
            color: ${props.activeColour};
        `
    }
`;

export default ActiveChangeColour;