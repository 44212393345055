import styled from "styled-components";

const FlexCentredDivContainer = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;
    margin: 0;
    padding: 0;
`;

const FlexPadding = styled.div`
    flex: 1;
`;

const FlexCentredDiv = ({children}: React.HTMLProps<HTMLDivElement>) => 
    <FlexCentredDivContainer>
        <FlexPadding />
            {children}
        <FlexPadding />
    </FlexCentredDivContainer>;

export default FlexCentredDiv;