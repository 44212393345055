import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BackgroundStandardPage, CentredDiv, Title } from "../Components/StyledComponents";

const Paragraph = styled.p`
    color: white;
    text-align: center;
`;

const WhiteSpan = styled.span`
    color: white;
    text-decoration: underline;

    &:hover {
        color: ${props => props.theme.main.light};
    }
`;

const WhiteLink = (props:React.HTMLProps<HTMLLinkElement>) => <Link to={props.href ?? ""}><WhiteSpan>{props.children}</WhiteSpan></Link>

const PricingPage = () => {
    return (
        <BackgroundStandardPage colour={"dark"}>
            <CentredDiv centreSize={"70%"}>
                <Title>User Account Pricing</Title>
                <CentredDiv centreSize={"90%"}>
                    <Paragraph>
                        The Australian Energy Market Masterclass is priced at $1,500 (AUD ex GST) per individual user, which will be invoiced on user activation.
                    </Paragraph>
                    <Paragraph>
                        The Australian Energy Market Masterclass content is held online, available on-demand and hosted on the <WhiteLink href="/">training.energyedge.com.au</WhiteLink> website. Our standard terms and conditions can be found <WhiteLink href="/terms" target="_blank">here</WhiteLink>.
                    </Paragraph>
                    <Paragraph>
                        Each individual user account will remain active for 12 months and is locked to the individual's email account. At the end of each quarter, each user will be emailed a report of their training activity for Continuous Professional Development (CPD) hours.
                    </Paragraph>
                </CentredDiv>

                <Title>Corporate Access</Title>
                <CentredDiv centreSize={"90%"}>
                    <Paragraph>
                        Energy Edge is willing to provide a quote on request for corporate or governmental access to the Australian Energy Market Masterclass.
                    </Paragraph>
                </CentredDiv>
            </CentredDiv>
    </BackgroundStandardPage>);
}

export default PricingPage;