import styled, { css } from "styled-components";

export interface AbsolutePositionedDivProps {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
}

const AbsolutePositionedDiv = styled("div")<AbsolutePositionedDivProps>`
    position: absolute;

    ${({top}) => top !== undefined && css`top: ${top}px;`}
    ${({right}) => right !== undefined && css`right: ${right}px;`}
    ${({bottom}) => bottom !== undefined && css`bottom: ${bottom}px;`}
    ${({left}) => left !== undefined && css`left: ${left}px;`}
`;

export default AbsolutePositionedDiv;