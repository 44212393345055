import React from 'react';
import styled from "styled-components";

export interface ICrumbProps {
    label: string;
    href: string;
}

const StyledCrumb = styled.li`

    li + &:before {
        content: " > ";
        padding: 0 10px;
    }

`;

const Crumb = ({label, href} : ICrumbProps) => {
    return <StyledCrumb><a href={href}>{label}</a></StyledCrumb>;
}

const StyledBreadCrumbs = styled.ul`
list-style: none;
display: flex;
margin: 0;
padding: 0;
`;

const BreadCrumbs = ({children} : React.HTMLProps<HTMLDivElement>) => {
    return (
        <StyledBreadCrumbs>
            {children}
        </StyledBreadCrumbs>
    );
}
export {
    BreadCrumbs,
    Crumb
};