import {useState} from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import styled from "styled-components";
import { BackgroundStandardPage, CentredDiv } from '../Components/StyledComponents';
import { Button, ErrorList, FlexPadding, Form, FormTitle, HtmlFocusEvent, InputField, InputGroup } from '../Components/StyledFormInputs';

import auth, { LoginState } from "../Services/AuthenticationService";

const CentreDiv = styled.div`
    display: flex;
    justify-content: center;
    width: 307px;
`;

const LoginForm = styled(Form)`
    margin: 0;
    padding: 0;
`;

const LoginButton = styled(Button)`
    font-size: 1em;
    padding: 5px;
    margin: 0;
    margin-top: 1rem;

    width: 50%;
    background-color: ${props => props.theme.main.dark};
    color: white;
`;

export interface ILoginPageProps {
    isLoggedIn: boolean;
    setIsLoggedIn: (state: boolean) => void;
}

export default function LoginPage({isLoggedIn, setIsLoggedIn}: ILoginPageProps) {

    const authService = auth.getService();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    
    const [submitResponse, setSubmitResponse] = useState<boolean|null>(null);
    const [errorList, setErrorList] = useState<string[]>([]);

    const query = new URLSearchParams(useLocation().search);
    const returnAddress = query.get("return") ?? "/library";

    const [loggingIn, setLoggingIn] = useState(false);
    const [redirectToGrantAccess, setRedirectToGrantAccess] = useState<boolean|null>(null);

    const loginCallback = async (event: any) => {
        event.preventDefault();
        
        setLoggingIn(true);
        setErrorList([]);
        const loginResult = await authService.login(email, password, true);
        setSubmitResponse(loginResult.isSuccess);
        if (!loginResult.isSuccess) {
            setErrorList(loginResult.messages);
        }

        setLoggingIn(false);
        if (loginResult.value === LoginState.ValidLogin) {
            setIsLoggedIn(loginResult.isSuccess);
        } else if (loginResult.value === LoginState.NoAppAccess) {
            setRedirectToGrantAccess(true);
        }
    };

    return (
        redirectToGrantAccess != null && redirectToGrantAccess ? <Redirect to={`/grant?return=${returnAddress}`} /> :
        isLoggedIn ? <Redirect to={returnAddress} /> :
        <BackgroundStandardPage colour={"background"}>
            <CentredDiv centreSize="50%">
                <LoginForm>
                    <FormTitle>Login to your account</FormTitle>
                    <InputField label="Email" 
                        handler={(e : HtmlFocusEvent) => setEmail(e.target.value)} 
                        validator={() => false} />
                    <InputField label="Password" 
                        type="password" 
                        handler={(e : HtmlFocusEvent) => setPassword(e.target.value)} 
                        validator={() => false} />
                    <InputGroup><FlexPadding />
                        {submitResponse !== null && !submitResponse && 
                            <ErrorList items={errorList}></ErrorList>
                        }
                    </InputGroup>
                    <InputGroup>
                    <FlexPadding />
                        <CentreDiv>
                            <Link to="/forgot">Forgot password</Link>
                        </CentreDiv>
                    </InputGroup>
                    <InputGroup>
                        <FlexPadding />
                        <CentreDiv>
                            <LoginButton disabled={loggingIn} onClick={loginCallback}>Login</LoginButton>
                        </CentreDiv>
                    </InputGroup>
                </LoginForm>
            </CentredDiv>
        </BackgroundStandardPage>
    );
}