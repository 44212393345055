import { ApiResponseStatus } from "../Components/ApiResponseStatus";
import IApiResponse from "../Components/IApiResponse";
import { Result, ValueResult } from "../Components/Result";
import config from "../config";
import authService from "../Services/AuthenticationService";
import { IRoleSummary } from "./RoleService";
import { IUserSummary } from "./UserService";

export interface IClientSummary {
    code: string;
    name: string;
    isEnabled: boolean;
}

export interface IClientDetail {
    code: string;
    name: string;
    isEnabled: boolean;
    accessCode: string;
    roles: IRoleSummary[];
    users: IUserSummary[];
}

export interface IClientService {
    addNewClient(name: string) : Promise<Result>;
    getSummaries() : Promise<ValueResult<IClientSummary[] | null>>;
    getDetail(code: string) : Promise<ValueResult<IClientDetail | null>>;
    setEnabledState(client: IClientSummary, newState: boolean) : Promise<Result>;
}

export class ClientService implements IClientService {

    private static instance : IClientService|null = null;
    public static getService() : IClientService {
        if (this.instance === null) {
            this.instance = new ClientService();
        }

        return this.instance;
    }

    public async addNewClient(name: string) : Promise<Result> {
        var tokenResult = await authService.getService().getToken();
        if (!tokenResult.isSuccess) {
            const currentUrl = window.location.href;
            // force immediate redirect to login page
            window.location.href = `/login?return=${currentUrl}`;
        }
        const token = tokenResult.value;

        try {
            const data = {
                name: name
            };

            const response = await fetch(`${config.apiHost}/clients`, {
                method: "post",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then(r => r.json() as Promise<IApiResponse<void>>);

            if (response.status !== ApiResponseStatus.Success) {
                return Result.Failed(response.error?.map(e => `${e.code}: ${e.message}`) ?? []);
            }

            return Result.Success();

        } catch (error) {
            return Result.Failed(["Could not add new client.", error]);
        }
    }

    public async getSummaries(): Promise<ValueResult<IClientSummary[] | null>> {

        var tokenResult = await authService.getService().getToken();
        if (!tokenResult.isSuccess) {
            const currentUrl = window.location.href;
            // force immediate redirect to login page
            window.location.href = `/login?return=${currentUrl}`;
        }
        const token = tokenResult.value;

        try {
            const response = await fetch(`${config.apiHost}/clients`, {
                method: "get",
                headers: {"Authorization": `Bearer ${token}`}
            }).then(r => r.json() as Promise<IApiResponse<IClientSummary[]>>);

            if (response.status !== ApiResponseStatus.Success) {
                return ValueResult.Failed<IClientSummary[]>(response.error?.map(e => `${e.code}: ${e.message}`) ?? []);
            }

            return ValueResult.Success(response.body ?? []);
        }
        catch (error) {
            return ValueResult.Failed<IClientSummary[]>(["Could not get client summaries.", error]);
        }
    }

    public async getDetail(code: string) : Promise<ValueResult<IClientDetail | null>> {
        var tokenResult = await authService.getService().getToken();
        if (!tokenResult.isSuccess) {
            const currentUrl = window.location.href;
            // force immediate redirect to login page
            window.location.href = `/login?return=${currentUrl}`;
        }
        const token = tokenResult.value;

        try {
            const response = await fetch(`${config.apiHost}/clients/${code}`, {
                method: "get",
                headers: {"Authorization": `Bearer ${token}`}
            }).then(r => r.json() as Promise<IApiResponse<IClientDetail>>);

            if (response.status !== ApiResponseStatus.Success) {
                return ValueResult.Failed<IClientDetail>(response.error?.map(e => `${e.code}: ${e.message}`) ?? []);
            }

            return ValueResult.Success(response.body);
        }
        catch (error) {
            return ValueResult.Failed<IClientDetail>(["Could not get client details.", error]);
        }
    }

    public async setEnabledState(client: IClientSummary, newState: boolean) : Promise<Result> {
        var tokenResult = await authService.getService().getToken();
        if (!tokenResult.isSuccess) {
            const currentUrl = window.location.href;
            // force immediate redirect to login page
            window.location.href = `/login?return=${currentUrl}`;
        }
        const token = tokenResult.value;

        try {
            const data = {
                name: client.name,
                isEnabled: newState
            };

            const response = await fetch(`${config.apiHost}/clients/${client.code}`, {
                method: "post",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then(r => r.json() as Promise<IApiResponse<void>>);

            if (response.status !== ApiResponseStatus.Success) {
                return Result.Failed(response.error?.map(e => `${e.code}: ${e.message}`) ?? []);
            }

            return Result.Success();
        } catch (error) {
            return Result.Failed(["Could not update client enabled state.", error]);
        }
    }
}