import styled from "styled-components";

const FullWindowHeightWrapper = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;

    margin: 0;
    padding: 0;
    min-height: 100vh;
    width: 100%;
`;

const FullWindowHeightPageWrapper = ({children} : React.HTMLProps<HTMLDivElement>) => {
    return (<FullWindowHeightWrapper>
        {children}
    </FullWindowHeightWrapper>);
}

export default FullWindowHeightPageWrapper;