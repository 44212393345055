import React from "react";
import styled from "styled-components";

const VerticalCentreGrid = styled.div`
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-rows: auto min-content auto;
`;

const GridItem = styled.div``;

const FullHeightVerticalCentredContainer = ({children} : React.HTMLProps<HTMLDivElement>) => 
    <VerticalCentreGrid>
        <GridItem />
        <GridItem>
            {children}
        </GridItem>
        <GridItem />
    </VerticalCentreGrid>;

export default FullHeightVerticalCentredContainer;