import React, { useEffect, useState } from 'react';
import { Redirect } from "react-router-dom";
import authService from "../Services/AuthenticationService";


export interface ILogoutPageProps {
    isLoggedIn: boolean;
    setIsLoggedIn: (state: boolean) => void;
}

export default function LogoutPage({isLoggedIn, setIsLoggedIn}: ILogoutPageProps) {
    
    const [finished, setFinished] = useState(false);

    useEffect(() => {
        if (isLoggedIn) {
            setIsLoggedIn(false);
        authService.getService().logout()
            .then(r => {
                setFinished(true);
            });
        }
    }, [isLoggedIn, setIsLoggedIn]);
    

    return (<>
        { finished ? <Redirect to="/" /> : <div></div>}
    </>);
}