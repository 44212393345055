import styled, { css } from "styled-components";

export interface IndexedChangeColourProps {
    colours: string[];
    index: number;
}

const IndexedChangeColour = styled("span")<IndexedChangeColourProps>`
    color: ${props => props.colours[0]};

    ${({index, colours}) => index < colours.length &&
        css`
            color: ${colours[index]};
        `
    }
`;

export default IndexedChangeColour;