import { useRef, useState } from "react";
import styled, { css } from "styled-components";
import ModuleExtendedSummary from "../Models/ModuleExtendedSummary";
import VideoSummary from "../Models/VideoSummary";

export interface ModuleExtendedSummaryAccordionDisplayProps {
    modules: ModuleExtendedSummary[];
}

const AccordionContainer = styled.div``;

const AccordionInner = styled.div`
    position: relative;
    width: 100%;
`;

const AccordionItem = styled("div")<{padBottom?: boolean, borderTop?: boolean}>`
    background-color: white;

    ${({padBottom}) => padBottom !== undefined && padBottom &&
        css`margin-bottom: 0.2rem;`
    }
    ${({borderTop}) => borderTop !== undefined && borderTop &&
        css`border-top: 1px solid black;`
    }

    &:last-child {
        padding-bottom: 0;
    }

`;

const AccordionItemTitle = styled("h3")<{colourIndex: number}>`
    margin: 0;
    padding: 1rem;
    cursor: pointer;
    background-color: ${props => props.theme.moduleColours[props.colourIndex % props.theme.moduleColours.length]};
    color: white;
`;
const AccordionItemSubtitle = styled.h4`
    margin: 0;
    padding: 1rem;
    cursor: pointer;
`;

const BackgroundHoverChange = styled.div`
    margin: 0;
    padding: 0;
    &:hover {
        background-color: #EEE;
    }
`;

const AccordionItemBody = styled("div")<{active: boolean, bodyHeight: number, noPadHorizontal?: boolean}>`
    display: block;
    position: relative;
    margin: 0;
    padding: 0 1rem;
    overflow: hidden;
    height: 0;
    transition: height 0.3s;

    ${({noPadHorizontal}) => noPadHorizontal !== undefined && noPadHorizontal &&
        css`
        padding-left: 0;
        padding-right: 0;
        `
    }

    ${({active, bodyHeight}) => active &&
        css`
        height: ${bodyHeight}px
        `
    }
`;

const AccordionItemBodyText = styled("p")<{ padBottom?: boolean}>`
    margin: 0;
    padding: 0;

    ${({padBottom}) => padBottom !== undefined && padBottom &&
        css`
        padding-bottom: 1rem;
        `
    }
`;

const AccordionVideoItem = ({video, heightChangeCallback}: {video: VideoSummary, heightChangeCallback: (height: number) => void}) => {

    const bodyRef = useRef<HTMLDivElement>(null);
    const [expanded, setExpanded] = useState(false);
    const [bodyHeight, setBodyHeight] = useState(0);

    const contentHeight = bodyRef.current?.scrollHeight ?? 100;

    const titleClickCallback = () => {
        if (expanded) {
            setExpanded(false);
            setBodyHeight(0);
            heightChangeCallback(-contentHeight); // reduce height change effect
        } else {
            setExpanded(true);
            setBodyHeight(contentHeight);
            heightChangeCallback(contentHeight);
        }
    }

    return (<AccordionItem borderTop={video.sortPosition > 1}>
        <BackgroundHoverChange>
            <AccordionItemSubtitle onClick={titleClickCallback}>{video.title}</AccordionItemSubtitle>
            <AccordionItemBody ref={bodyRef} active={expanded} bodyHeight={bodyHeight}>
                <AccordionItemBodyText padBottom={true}>{video.summary}</AccordionItemBodyText>
            </AccordionItemBody>
        </BackgroundHoverChange>
    </AccordionItem>);
}

const AccordionModuleItem = ({module, expanded, setExpanded}: {module: ModuleExtendedSummary, expanded: boolean, setExpanded: (state: boolean) => void}) => {

    const bodyRef = useRef<HTMLDivElement>(null);
    const [bodyHeight, setBodyHeight] = useState(0);

    const titleClickCallback = () => {
        if (expanded) {
            setExpanded(false);
        } else {
            setExpanded(true);
            setBodyHeight(bodyRef.current?.scrollHeight ?? 0);
        }
    };

    const videoHeightChanged = (height: number) => {
        if (expanded) {
            setBodyHeight(bodyHeight + height);
        }
    };

    module.videos.sort((a,b) => a.sortPosition - b.sortPosition);

    return (<AccordionItem  padBottom={true}>
        <AccordionItemTitle onClick={titleClickCallback} colourIndex={(module.sortPosition-1)}>MODULE {module.sortPosition} | {module.label}</AccordionItemTitle>
        <AccordionItemBody ref={bodyRef} active={expanded} bodyHeight={bodyHeight} noPadHorizontal={true}>
            {module.videos.map(v => <AccordionVideoItem key={v.identifier} video={v} heightChangeCallback={videoHeightChanged}/>)}
        </AccordionItemBody>
    </AccordionItem>);
}


const HomepageModuleAccordion = ({modules} : ModuleExtendedSummaryAccordionDisplayProps) => {

    const [activeItem, setActiveItem] = useState(-1);

    const setExpandedWrapper = (index: number) => {

        return (state: boolean) => {
            if (state) {
                setActiveItem(index);
            } else if (activeItem === index) {
                setActiveItem(-1);
            }
        }
    }

    modules.sort((a,b) => a.sortPosition - b.sortPosition);

    return (<AccordionContainer>
        <AccordionInner>
            {modules.map((m,i) => <AccordionModuleItem key={m.identifier} module={m} expanded={i === activeItem} setExpanded={setExpandedWrapper(i)} />)}
        </AccordionInner>
    </AccordionContainer>);
}

export default HomepageModuleAccordion;