import styled from 'styled-components';

import NavBar from './NavBar';

import logo from '../assets/EE-Training-Hoz.svg';

import {FlexPadding, HorizontalFlexContainer, VerticalFlexContainer} from './StyledComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';

interface IHeaderProps {
    isLoggedIn: boolean;
    isAdmin: boolean;
}

const Image = styled.img`
    height: 80px;
    padding: 20px 0;

    @media (max-width: 750px) {
        width: 100%;
        padding: 5px;
        margin: 0;
    }
`;

const Link = styled.a`
`;

const MobileMenuToggleContainer = styled.div`
    display: none;
    padding: 0.5rem;

    @media (max-width: 750px) {
        display: block;
    }
`;

const MobileMenuToggle = (props: React.HTMLProps<HTMLDivElement>) => {
    return (<MobileMenuToggleContainer onClick={props.onClick}>
        <FontAwesomeIcon icon={faBars} />
    </MobileMenuToggleContainer>);
}

const Header = ({isLoggedIn, isAdmin}:IHeaderProps) => {

    const [menuToggleVisible, setMenuToggleVisible] = useState(false);

    const toggleMenuCallback = () => {
        setMenuToggleVisible(!menuToggleVisible);
    }

    return (<header>
        <HorizontalFlexContainer>
            <FlexPadding />
            <Link href="/"><Image src={logo} alt="Energy Training" /></Link>
            <FlexPadding />
            <VerticalFlexContainer>
                <FlexPadding />
                <MobileMenuToggle onClick={toggleMenuCallback}/>
                <FlexPadding />
            </VerticalFlexContainer>
        </HorizontalFlexContainer>
        
        <NavBar isLoggedIn={isLoggedIn} isAdmin={isAdmin} toggleVisible={menuToggleVisible}/>
    </header>);
}

export default Header;