
import styled from "styled-components";

import eeLogo from '../assets/EE-Master-Hoz-White.svg';
import emailIcon from '../assets/icon-email.png';
import phoneIcon from '../assets/icon-phone.png';
import linkedInIcon from '../assets/icon-linkedin.png';
import FlexCentredDiv from "./styled/FlexCentredDiv";

const LogoImage = styled.img`
    height: 50px;
    padding: 10px 0;
`;

const SocialImage = styled.img`
    height: 40px;
    margin: 10px;
`;

const WelcomeFooter = styled.footer`
    background-color: ${props => props.theme.main.dark};
    width: 100%;
`;

const Link = styled.a`
color: ${props => props.theme.main.mid};
`;

export default function Footer () {
    return (<WelcomeFooter>
        <FlexCentredDiv>
            <Link href="https://energyedge.com.au"><LogoImage src={eeLogo} alt="Energy Edge"/></Link>
        </FlexCentredDiv>
        <FlexCentredDiv>
            <Link href="mailto:info@energyedge.com.au"><SocialImage src={emailIcon} /></Link>
            <Link href="tel:+61732118399"><SocialImage src={phoneIcon} /></Link>
            <Link href="https://www.linkedin.com/company/energy-edge-pty-ltd"><SocialImage src={linkedInIcon} /></Link>
        </FlexCentredDiv>
    </WelcomeFooter>);
}