import { ApiResponseStatus } from "../Components/ApiResponseStatus";
import IApiResponse from "../Components/IApiResponse";
import { Result, ValueResult } from "../Components/Result";
import config from "../config";
import VideoSummary from "../Models/VideoSummary";
import AuthenticationService from "./AuthenticationService";

export interface IFavouriteService {
    getAllFavourites() : Promise<ValueResult<VideoSummary[]|null>>;
    addToFavourites(videoId: string) : Promise<Result>;
    removeFromFavourites(videoId: string) : Promise<Result>;
}

export default class FavouriteService implements IFavouriteService {

    private static instance : IFavouriteService | null= null;

    public static getInstance() : IFavouriteService {
        if (this.instance === null) {
            this.instance = new FavouriteService();
        }
        return this.instance;
    }

    async getAllFavourites(): Promise<ValueResult<VideoSummary[]|null>> {
        const tokenResult = await AuthenticationService.getService().getToken();
        if (!tokenResult.isSuccess || tokenResult.value === null) {
            return ValueResult.Failed<VideoSummary[]>(tokenResult.messages ?? []);
        }

        try {
            const token = tokenResult.value;
            const result = await fetch(`${config.apiHost}/favourites`, {
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`
                },
            }).then(r => r.json() as Promise<IApiResponse<VideoSummary[]>>);

            if (result.status !== ApiResponseStatus.Success) {
                return ValueResult.Failed<VideoSummary[]>(result.error?.map(e => `${e.code}: ${e.message}`) ?? []);
            } else if (result.body === null) {
                return ValueResult.Failed<VideoSummary[]>(["Unexpected error: Api returned success with no favourite details"]);
            }

            return ValueResult.Success(result.body);

        } catch (e) {
            return ValueResult.Failed<VideoSummary[]>(e);
        }
    }
    async addToFavourites(videoId: string): Promise<Result> {
        const tokenResult = await AuthenticationService.getService().getToken();
        if (!tokenResult.isSuccess || tokenResult.value === null) {
            return Result.Failed(tokenResult.messages ?? []);
        }

        try {
            const token = tokenResult.value;
            const data = {
                videoIdentifier: videoId
            };

            const result = await fetch(`${config.apiHost}/favourites/add`, {
                method: "post",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then(r => r.json() as Promise<IApiResponse<void>>);

            if (result.status !== ApiResponseStatus.Success) {
                return Result.Failed(result.error?.map(e => `${e.code}: ${e.message}`) ?? []);
            }

            return Result.Success();

        } catch (e) {
            return Result.Failed(e);
        }
    }
    async removeFromFavourites(videoId: string): Promise<Result> {
        const tokenResult = await AuthenticationService.getService().getToken();
        if (!tokenResult.isSuccess || tokenResult.value === null) {
            return Result.Failed(tokenResult.messages ?? []);
        }

        try {
            const token = tokenResult.value;
            const data = {
                videoIdentifier: videoId
            };

            const result = await fetch(`${config.apiHost}/favourites/remove`, {
                method: "post",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then(r => r.json() as Promise<IApiResponse<void>>);

            if (result.status !== ApiResponseStatus.Success) {
                return Result.Failed(result.error?.map(e => `${e.code}: ${e.message}`) ?? []);
            }

            return Result.Success();

        } catch (e) {
            return Result.Failed(e);
        }
    }

}