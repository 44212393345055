import styled from "styled-components";


const Grid = styled.div`
    display: grid;
`;

const GridItem = styled.div`
    display: grid;
`;

export {
    Grid,
    GridItem
};