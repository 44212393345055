import styled, { css } from "styled-components";

const StyledNavBar = styled("nav")`
    background-color: ${props => props.theme.main.mid}
`;

const MenuList = styled("div")<{toggleVisible: boolean}>`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;

    flex-wrap: hidden;

    @media (max-width: 750px) {
        flex-direction: column;   
        max-height: 0;
        transition: max-height .3s ease-in-out;
        overflow: hidden;

        ${({toggleVisible}) => toggleVisible &&
            css`
                max-height: 1000px;
            `
        }
    }
`;

const MenuItem = styled.a`
    min-width: 2em;
    margin: 0 0.5em;
    padding: 1em;
    font-size: 1.1em;
    font-weight: bold;
    text-decoration: none;

    color: white;
    
    :hover {
        background-color: ${props => props.theme.main.light};
        color: ${props => props.theme.main.dark};
    }
`;

const EndMenuItem = styled(MenuItem)``;

const FullHeightDiv = styled.div`
    display: flex;
    height: 100%;
    @media (max-width: 750px) {
        flex-direction: column;   
    }
`;

const RightAlignDiv = styled(FullHeightDiv)`
    @media (min-width: 750px) {
        position: absolute;
        right: 0;
    }
`; 

export interface INavBarProps {
    isLoggedIn: boolean;
    isAdmin: boolean;
    toggleVisible: boolean;
}

export default function NavBar({isLoggedIn, isAdmin, toggleVisible}: INavBarProps) {

    return (
        <StyledNavBar>
            <MenuList toggleVisible={toggleVisible}>
                
                <FullHeightDiv>
                    <MenuItem href="/">Home</MenuItem>
                    { !isLoggedIn && <MenuItem href="/content">Content</MenuItem> }
                    { isLoggedIn && <MenuItem href="/library">Library</MenuItem> }
                    { !isLoggedIn && <MenuItem href="/preview">Preview</MenuItem> }
                    <MenuItem href="/pricing">Pricing</MenuItem>
                    <MenuItem href="https://energyedge.com.au/contact-us/" target="_blank">Contact us</MenuItem>
                </FullHeightDiv>
                <RightAlignDiv>
                    <FullHeightDiv>
                        { !isLoggedIn && <>
                            <EndMenuItem href="/register">Register</EndMenuItem>
                            <EndMenuItem href="/login">Login</EndMenuItem>
                        </>}
                        { isLoggedIn && <>
                            { isAdmin && <EndMenuItem href="/admin">Admin</EndMenuItem>}
                            <EndMenuItem href="/logout">Logout</EndMenuItem>
                        </>} 
                    </FullHeightDiv>  
                </RightAlignDiv>
            </MenuList>
        </StyledNavBar>
    );
}