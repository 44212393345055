
import { faCircle as faClosedCircle, faAdjust } from "@fortawesome/free-solid-svg-icons";
import { faCircle as faOpenCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import IndexedChangeColour from "./styled/IndexedChangeColour";

export interface HistoryProgressItemProps {
    hasStarted: boolean;
    completionCount: number;
    maxCompletionCount: number;
}

const HistoryProgressItem = ({hasStarted, completionCount, maxCompletionCount}: HistoryProgressItemProps) => {

    const [statusMessage, setStatusMessage] = useState("");
    const [icon, setIcon] = useState(faOpenCircle);
    const [colourIndex, setColourIndex] = useState(0);

    const [anyCompletions, setAnyCompletions] = useState(completionCount > 0);
    const [lastCompletion, setLastCompletion] = useState(maxCompletionCount-completionCount === 1);
    const [allCompletions, setAllCompletions] = useState(completionCount >= maxCompletionCount);

    useEffect(() => {
        setAnyCompletions(completionCount > 0);
        setLastCompletion(maxCompletionCount-completionCount === 1);
        setAllCompletions(completionCount >= maxCompletionCount);
    },[completionCount, maxCompletionCount]);

    useEffect(() => {
        if (!hasStarted) {
            setStatusMessage("Not started");
            setIcon(faOpenCircle);
            setColourIndex(0);
            return; // do nothing else if we haven't started
        }
        if (!anyCompletions) {
            setStatusMessage("In progress");
            setColourIndex(1);
            setIcon(faAdjust);
        }
        if (anyCompletions) {
            setStatusMessage(`Completed ${completionCount} of ${maxCompletionCount} views.`);
            setColourIndex(1);
            setIcon(faClosedCircle);
        }
        if (lastCompletion) {
            setColourIndex(2);
            setIcon(faClosedCircle);
        }
        if (allCompletions) {
            setStatusMessage(`Completed all ${maxCompletionCount} views. Please contact Energy Edge to obtain additional views.`);
            setColourIndex(3);
            setIcon(faClosedCircle);
        }

    }, [hasStarted, anyCompletions, lastCompletion, allCompletions, completionCount, maxCompletionCount]);

    return (<IndexedChangeColour colours={["#AAAAAA", "#14702d", "#FFFF00", "#FF0000"]} index={colourIndex} title={statusMessage}>
        {<FontAwesomeIcon icon={icon} />}
    </IndexedChangeColour>);
}

export default HistoryProgressItem;