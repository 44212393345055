
import styled from "styled-components";

const Paragraph = styled.p`
    color: ${props => props.theme.main.dark};
    text-align: center;
`;

const List = styled.ul``;
const ListItem = styled.li``;

const AcceptableUsePolicy = () =>
    <>
    <Paragraph>
        The Australian Energy Market Masterclass is provided to assist in your education, and we appreciate the trust you have placed in Energy Edge to conduct this education and training. In exchange we trust you to use our services in the manner in which it is intended. This includes:
    </Paragraph>
    <List>
        <ListItem>
            You must only use your subscription to the Masterclass for the intended purpose of solely training you. Sharing of the Masterclass content with non-subscribers in any way is prohibited. 
        </ListItem>
        <ListItem>
            You must not provide your user credentials to another person to enable viewing of the Masterclass by any other person.
        </ListItem>
        <ListItem>
            You must not save or copy the Masterclass content via screen capture or recording or any other means. 
        </ListItem>
        <ListItem>
            You must not use your user credentials to broadcast, publish, share, or stream the Masterclass content to non-subscribers. 
        </ListItem>
        <ListItem>
            As part of your subscription, you will be given the ability to watch each training video up to three times within a twelve month period. You must not manipulate your viewing habits to avoid detection of the number of times you have viewed each video.
        </ListItem>
    </List>
</>;

export default AcceptableUsePolicy;