import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import config from "../config.js";
import { StandardPage, Title, VerticalFlexContainer } from "../Components/StyledComponents";
import {ErrorList} from "../Components/StyledFormInputs";

import AuthenticationService from "../Services/AuthenticationService";

interface IFileDetails {
    mediaId: string;
    url: string;
    size: number;
    type: string;
}

enum LoadingState {
    Loading,
    CompleteSuccess,
    CompleteFailed
}

interface ILoadingProps {
    state: LoadingState;
    loading: JSX.Element;
    success: JSX.Element;
    failed: JSX.Element;
}

const Loading = ({state, loading, success, failed} : ILoadingProps) => {
    if (state === LoadingState.Loading) {
        return loading;
    } else if (state === LoadingState.CompleteSuccess) {
        return success;
    } else if (state === LoadingState.CompleteFailed) {
        return failed;
    } else {
        return <p>Unknown loading state.</p>
    }
};

const byteSizeToHumanReadableSize = (b: number) : string => {
    if (b < 1024) {
        return "< 1KiB"
    }
    const kb = b/1024.0;
    if (kb < 1024) {
        return `${kb.toFixed(1)}KiB`;
    }
    const mb = kb/1024.0;
    if (mb < 1024) {
        return `${mb.toFixed(1)}MiB`;
    }
    const gb = mb/1024.0;
    if (gb < 1024) {
        return `${gb.toFixed(1)}GiB`;
    }
    return "> 1TiB";
};

const LoadingDisplay = () => <h3>Preparing file for download. Please wait.</h3>
const FileDisplay = ({ details } : {details: IFileDetails}) =>
     (<table>
        <tbody>
            <tr>
                <th>Identifier</th><td>{details.mediaId}</td>
            </tr>
            <tr>
                <th>File type</th><td>{details.type}</td>
            </tr>
            <tr>
                <th>File size</th><td>{byteSizeToHumanReadableSize(details.size)}</td>
            </tr>
            <tr>
                <td></td>
                <td>
                    <a href={details.url} target="_blank" rel="noreferrer" download={details.mediaId}>Open file</a>
                </td>
            </tr>
        </tbody>
    </table>);

const ErrorDisplay = ( {items} : {items: string[]}) => (
    <VerticalFlexContainer>
        <h3>Failed to prepare file</h3>
        <ErrorList items={items} />
    </VerticalFlexContainer>);

const MediaPage = () => {

    const { mediaId } = useParams<{mediaId: string}>();

    const [loadingState, setLoadingState] = useState(LoadingState.Loading);
    const [fileDetails, setFileDetails] = useState<IFileDetails>({mediaId: "", url: "", size: 0, type: ""});
    const [errorList, setErrorList] = useState<string[]>([]);

    useEffect(() => {

        async function getMedia() {

            var tokenResponse = await AuthenticationService.getService().getToken();

            if (!tokenResponse.isSuccess) {
                setErrorList(tokenResponse.messages);
                setLoadingState(LoadingState.CompleteFailed);
                return;
            }

            var response = await fetch(`${config.apiHost}/media/${mediaId}`, {
                method: "get",
                headers: {"Authorization": `Bearer ${tokenResponse.value}`}
            });
            
            if (response.status === 200) {
                
                var blob = await response.blob();
                const url = URL.createObjectURL(blob);

                setFileDetails({
                    mediaId: mediaId,
                    url: url,
                    size: blob.size,
                    type: blob.type
                });
                setLoadingState(LoadingState.CompleteSuccess);

            } else {
                setLoadingState(LoadingState.CompleteFailed);
                if (response.status === 404) {
                    setErrorList(["File not found."]);
                    setLoadingState(LoadingState.CompleteFailed);
                }
                else if (response.status === 401 || response.status === 403) {
                    setErrorList(["You do not have permission to access this file."]);
                    setLoadingState(LoadingState.CompleteFailed);
                }
                else {
                    setErrorList(["An unknown error occurred."]);
                    setLoadingState(LoadingState.CompleteFailed);
                }
            }
        }

        getMedia(); // run asynchronously

    }, [mediaId]);

    return (<StandardPage>
        <Title>Download file</Title>
        <Loading 
            state={loadingState}
            loading={<LoadingDisplay />}
            success={<FileDisplay details={fileDetails} />}
            failed={<ErrorDisplay items={errorList} />}
        />
    </StandardPage>);
};


export default MediaPage;