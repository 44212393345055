import styled from "styled-components";
import { BackgroundStandardPage, Title, VerticalFlexContainer } from "../Components/StyledComponents";

import CentredDiv from "../Components/styled/CentredDiv";
import FlexCentredDiv from "../Components/styled/FlexCentredDiv";
import ExplicitVerticalPadding from "../Components/styled/ExplicitVerticalPadding";

const WelcomeTitle = styled(Title)`
    color: ${props => props.theme.main.light};
`;

const WelcomeDetail = styled.div`
    color: white;
    margin: 1em auto 0 auto;
    width: 70%;
    text-align: center;

    @media (max-width: 700px) {
        width: 100%;
    }
`;

const VideoPlayer = styled.iframe`
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    frameborder: 0;
`;

const MaintainAspectRatio = styled.div`
    position: relative;
    max-height: 90vh;
    max-width: 70vw;

    height: calc(70vw*9/16); 
    width: calc(90vh*16/9);
`;


export default function HomePage() {


    return (<div>
        <BackgroundStandardPage colour={"dark"}>
            <CentredDiv centreSize={"90%"}>
                <VerticalFlexContainer>
                    <WelcomeTitle>The Australian Energy Market Masterclass</WelcomeTitle>

                    <WelcomeDetail>
                    <p>
                        Energy Edge has developed an online Australian Energy Market Masterclass to bring sense to the notoriously complex Australian energy markets.
                    </p>
                    <p>
                        Our presenter, Elliot Tonkes, brings twenty years of experience as one of the most respected risk analysts in the Australian Energy Market. His engaging approach to lecturing will help newcomers through to experienced energy practitioners understand these complex topics through a “deep-dive” approach to specific aspects of the market.
                    </p>
                    </WelcomeDetail>
                </VerticalFlexContainer>
            </CentredDiv>
            <CentredDiv centreSize={"90%"}>
                <FlexCentredDiv>
                    <MaintainAspectRatio>
                        <VideoPlayer src="https://player.vimeo.com/video/519785175" 
                            allow="autoplay; fullscreen; picture-in-picture" >
                        </VideoPlayer>
                    </MaintainAspectRatio>
                </FlexCentredDiv>
                <ExplicitVerticalPadding height={"1rem"} />
            </CentredDiv>
        </BackgroundStandardPage>
    </div>);
};