import styled from "styled-components";

const DivContainer = styled("div")<{centreSize: string}>`
    position: relative;
    display: grid;
    grid-template-columns: auto ${props => props.centreSize} auto;
    margin: 0;
    padding: 0;
    width: 100%;

    @media (max-width: 750px) {
        grid-template-columns: 0 auto 0;
    }
`;
const DivFiller = styled.div`
    margin: 0;
    padding: 0;
`;
const DivContent = styled.div`
    position: relative;
    margin: 0;
    padding: 0;
`;

export interface ICentredDivProps extends React.HTMLProps<HTMLDivElement> {
    centreSize?: string;
}

const CentredDiv = ({children, centreSize}: ICentredDivProps) => 
    <DivContainer centreSize={centreSize ?? "100%"}>
        <DivFiller />
        <DivContent>
            {children}
        </DivContent>
        <DivFiller />
    </DivContainer>;

export default CentredDiv;