import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { ApiResponseStatus } from "../Components/ApiResponseStatus";
import FavouriteItem from "../Components/FavouriteItem";
import HistoryProgressItem from "../Components/HistoryProgressItem";
import IApiResponse from "../Components/IApiResponse";
import IModuleDetail from "../Components/IModuleDetail";
import ModuleLibraryPageModuleAccordion from "../Components/ModuleLibraryPageModuleAccordion";
import AbsolutePositionedDiv from "../Components/styled/AbsolutePositionedDiv";
import DarkTitle from "../Components/styled/DarkTitle";
import DropdownButtonIndicator from "../Components/styled/DropdownButtonIndicator";
import ExplicitVerticalPadding from "../Components/styled/ExplicitVerticalPadding";
import { BackgroundPage, CentredDiv } from "../Components/StyledComponents";
import config from "../config";
import HistoryWatchPosition from "../Models/HistoryWatchPosition";
import ModuleExtendedSummary from "../Models/ModuleExtendedSummary";
import ModuleSummaryModel from "../Models/ModuleSummaryModel";
import VideoSummary from "../Models/VideoSummary";
import FavouriteService from "../Services/FavouriteService";
import HistoryService from "../Services/HistoryService";
import ModuleService from "../Services/ModuleService";

const NoteParagraph = styled.p`
background-color: ${props => props.theme.error.background};
    color: ${props => props.theme.error.font};
    margin: 0;
    padding: 0;
`;

const ModuleListing = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 750px) {
        flex-direction: column;
    }
`;

const ModuleHeaderItem = styled("button")<{active: boolean}>`
    background-color:${props => props.theme.main.background};
    display: flex;
    margin: 0em;
    padding: 1em;
    cursor: pointer;

    flex-grow: 1;
    
    border: 0;
    box-shadow: none;
    border-radius: 0px;

    color: ${props => props.theme.main.dark};
    border-left: 1px solid ${props => props.theme.main.dark};
    &:nth-child(1) {
        border-left: none;
    };

    ${({active}) => active &&
        css`
            font-weight: bold;
            background-color:${props => props.theme.main.light};
            border-top: 1px solid ${props => props.theme.main.dark};
            border-bottom: 1px solid ${props => props.theme.main.dark};

            &:nth-child(1) {
                border-left: 1px solid ${props => props.theme.main.dark};
            };

            &:last-child {
                border-right: 1px solid ${props => props.theme.main.dark};
            };
        `
    }

    @media (max-width: 750px) {
        border-left: 1px solid ${props => props.theme.main.dark} !important;
        border-right: 1px solid ${props => props.theme.main.dark};
        border-bottom: 1px solid ${props => props.theme.main.dark};

        &:hover {
            background-color:${props => props.theme.main.mid};
            color: white;
        }
    }
`;

const VideoBlockCollection = styled.div`
    margin: 0 auto;
    padding: 0;
    width: 100%;
    display: grid;
    grid-template-columns: auto 35% 35% auto;
    grid-column-gap: 1em;
    grid-row-gap: 1em;

    @media (max-width: 1440px) {
        grid-template-columns: 0 auto auto 0;
    }

    @media (max-width: 750px) {
        grid-template-columns: 0 auto 0;
    }
`;

const FullWidthImage = styled.img`
    width: 100%;
`;

const NoMarginParagraph = styled.p`
    margin: 0;
    padding: 0;
`;

const Card = styled.div`
    position: relative;
    display: inline-block;
    background-color: white;
    padding: 0;
    vertical-align: top;
    
    &:nth-child(odd) {
        grid-column-start: 2;
    }

    @media (max-width: 750px) {
        grid-column-start: 2;
    }
`;

const CardBody = styled.div`
    margin: 0;
    padding: 0.5em;
`;

interface ExtendedVideoSummary extends VideoSummary {
    favourited: boolean;
    hasStarted: boolean;
    completionCount: number;
    maxCompletionCount: number;
}

interface IVideoBlockProps {
    video: ExtendedVideoSummary;
}
const Link = styled.a`
    color: ${props => props.theme.main.mid};
`;

interface IMaybeLinkContentProps extends React.HTMLProps<HTMLDivElement> {
    makeLink: boolean;
    link: string
}

const MaybeLinkContent = ({makeLink, link, children}: IMaybeLinkContentProps) => {
    return makeLink 
        ? (<Link href={`${link}`}>{children}</Link>)
        : (<>{children}</>)
    ;
}

const VideoBlock = ({video} : IVideoBlockProps) => {

    const [active, setActive] = useState(video.favourited);

    return (
        <Card>
            <MaybeLinkContent makeLink={video.isAccessible} link={`/play/${video.identifier}`}>
                <FullWidthImage src={video.previewUrl ?? undefined} alt={video.title} />
            </MaybeLinkContent>
            <AbsolutePositionedDiv top={5} left={5}>
                <FavouriteItem active={active} setActive={setActive} videoIdentifier={video.identifier}/>
            </AbsolutePositionedDiv>
            
            <AbsolutePositionedDiv top={5} right={5}>
                <HistoryProgressItem hasStarted={video.hasStarted} completionCount={video.completionCount} maxCompletionCount={video.maxCompletionCount}/>
            </AbsolutePositionedDiv>
            <CardBody>
                <NoMarginParagraph>{video.summary}{video.durationMinutes > 0 ? ` (${video.durationMinutes} min)` : ""}</NoMarginParagraph>
            </CardBody>
        </Card>
    )
};

const ModuleHeader = ({module, active, clickHandler}:{module:ModuleSummaryModel, active: boolean, clickHandler: (id: string) => void}) => {
    return <ModuleHeaderItem active={active} onClick={() => clickHandler(module.identifier)}>{module.label}</ModuleHeaderItem>
};

const FavouriteHeader = ({active, clickHandler}: {active: boolean, clickHandler: () => void}) => 
    <ModuleHeaderItem active={active} onClick={() => clickHandler()}>Favourites</ModuleHeaderItem>;

const CollapsibleWrapper = styled.div`
    display: relative;
    width: 100%;
    margin: 0;
    padding: 0;
`;

const CollapsibleLabelWrapper = styled.div`
    display: none;

    @media (max-width: 750px) {
        display: flex;
        flex-direction: row;
        vertical-align: baseline;

        border: 1px solid ${props => props.theme.main.dark};
        padding: 1rem;
        background-color: ${props => props.theme.main.light};
        font-weight: bold;
        cursor: pointer;
    }
`;

const CollapsibleLabel = styled.label`
    width: 100%;
    cursor: pointer;
`;
const CollapsibleContent = styled("div")<{expanded: boolean}>`
    
    overflow: hidden;

    transition: max-height .3s ease-in-out;

    @media (min-width: 750px) {
        max-height: 1000px;
    }

    @media (max-width: 750px) {
        max-height: 0px;
        ${({expanded}) => expanded &&
            css`
                max-height: 1000px;
            `
        }
    }
`;
const CollapsibleContentInner = styled.div``;


interface CollapsibleModuleListingProps extends React.HTMLProps<HTMLDivElement> {
    label: string;
    expanded: boolean;
    setExpanded: (state:boolean) => void;
}

const CollapsibleModuleListing = ({label, children, expanded, setExpanded} : CollapsibleModuleListingProps) => {
    
    const labelClickCallback = () => {
        setExpanded(!expanded);
    }

    return (<CollapsibleWrapper>
        <CollapsibleLabelWrapper onClick={labelClickCallback}>
            <CollapsibleLabel>{label}</CollapsibleLabel>
            <DropdownButtonIndicator />
        </CollapsibleLabelWrapper>
        <CollapsibleContent expanded={expanded}>
            <CollapsibleContentInner>
                <ModuleListing>
                    {children}
                </ModuleListing>
            </CollapsibleContentInner>
        </CollapsibleContent>
    </CollapsibleWrapper>);
};

const ModulePageGrid = styled.div`
    display: grid;
    grid-template-columns: min-content 5% auto 5%;

    @media (max-width: 1440px) {
        grid-template-columns: min-content 1% auto 1%;
    }
`;

const ModulePageGridItem = styled.div``;

export default function ModuleLibraryPage() {

    const [modules, setModules] = useState<ModuleExtendedSummary[]>([]);

    const [favouritesSelected, setFavouritesSelected] = useState(false);
    const [selectedModuleId, setSelectedModuleId] = useState<string|null>(null);
    
    const [moduleDetails, setModuleDetails] = useState<IModuleDetail|null>(null);
    const [extendedModuleVideos, setExtendedModuleVideos] = useState<ExtendedVideoSummary[]>([]);
    const [favourites, setFavourites] = useState<VideoSummary[]|null>(null);
    const [histories, setHistories] = useState<HistoryWatchPosition[]|null>(null);

    useEffect(() => {
        const getModules = async () => {
            const moduleResponse = await fetch(`${config.apiHost}/modules?view=extended`)
                                        .then(r => r.json() as Promise<IApiResponse<ModuleExtendedSummary[]>>);

            if (moduleResponse.status === ApiResponseStatus.Error) {
                // something about failed request
                return;
            }
            if (moduleResponse.status === ApiResponseStatus.Failed) {
                // something about bad request
                return;
            }

            setModules(moduleResponse.body ?? []);
            if (moduleResponse.body !== null) {
                setFavouritesSelected(false);
                setSelectedModuleId(moduleResponse.body[0].identifier);
            }
            
        }

        const favouritesCallback = async() => {
            const favouritesResult = await FavouriteService.getInstance().getAllFavourites();

            if (favouritesResult.isSuccess) {
                setFavourites(favouritesResult.value ?? []);
            } else {
                setFavourites([]);
            }
        }

        const historiesCallback = async() => {
            const historiesResult = await HistoryService.getService().getAllHistories();

            if (historiesResult.isSuccess) {
                setHistories(historiesResult.value ?? []);
            } else {
                setHistories([]);
            }
        }

        getModules();
        favouritesCallback();
        historiesCallback();
    },[]);

    useEffect(() => {
        if (selectedModuleId === null) {
            return; // do nothing
        }

        const moduleDetailscallback = async() => {
            const details = await ModuleService.getService().getDetails(selectedModuleId);
            
            if (details.isSuccess) {
                setModuleDetails(details.value);
            } else {
                setPageNote("Your account has not yet been granted permission to access training materials by Energy Edge. You will receive an email when this is permitted.");
            }
        }

        moduleDetailscallback();

    }, [selectedModuleId]);

    useEffect(() => {
        if (moduleDetails === null || favourites === null || histories === null) {
            // everything not populated, so no need to continue
            return;
        }

        const extendedVideos = moduleDetails.videos.map(v => {

            const hasFavourited = favourites.filter(f => f.identifier === v.identifier).length > 0;
            const history = histories.filter(h => h.videoIdentifier === v.identifier);

            let hasStarted = false;
            let completionCount = 0;
            let maxCompletionCount = 3;
            if (history.length > 0) {
                hasStarted = history[0].currentPosition > 0;
                completionCount = history[0].completionCount;
                maxCompletionCount = history[0].maxCompletionCount;
            }

            return Object.assign({}, v, {
                favourited: hasFavourited,
                hasStarted: hasStarted,
                completionCount: completionCount,
                maxCompletionCount: maxCompletionCount
            }) as ExtendedVideoSummary;
        });

        setExtendedModuleVideos(extendedVideos);

    }, [moduleDetails, favourites, histories]);

    const [pageNote, setPageNote] = useState<string|null>(null);
    
    const [expanded, setExpanded] = useState(false);

    const moduleSelectedCallback = (newModule: string) => {
        setExpanded(false);
        setSelectedModuleId(newModule);
        setFavouritesSelected(false);
    }

    const showFavourites = () => {
        setExpanded(false);
        setSelectedModuleId(null);
        setFavouritesSelected(true);
        setExtendedModuleVideos([]);

        

        const videos = favourites?.map(v => {

            const maybeHistory = histories?.filter(h => h.videoIdentifier === v.identifier);

            let history = {
                hasStarted: false,
                completionCount: 0,
                maxCompletionCount: 3
            };
            if (maybeHistory !== undefined && maybeHistory.length > 0) {
                history = {
                    hasStarted: maybeHistory[0].currentPosition > 0,
                    completionCount: maybeHistory[0].completionCount,
                    maxCompletionCount: maybeHistory[0].maxCompletionCount
                };
            }

            return Object.assign({}, v, {
                favourited: true
            }, history) as ExtendedVideoSummary;
        });

        setExtendedModuleVideos(videos ?? []);
    }
    
    return (
        <BackgroundPage colour={"background"}>
            <DarkTitle>Library</DarkTitle>
            
            <CentredDiv>
                {pageNote != null && <NoteParagraph>{pageNote}</NoteParagraph>}
            </CentredDiv>

            <ModulePageGrid>
                <ModulePageGridItem>
                    <ModuleLibraryPageModuleAccordion modules={modules} favourites={favourites ?? []} histories={histories ?? []} />
                </ModulePageGridItem>

                <ModulePageGridItem />
                <ModulePageGridItem>
                    <CentredDiv>
                        <CollapsibleModuleListing
                            label={moduleDetails?.label ?? ""}
                            expanded={expanded}
                            setExpanded={setExpanded}
                            >
                            {modules.map((m) => <ModuleHeader key={m.identifier}
                                                            module={m} 
                                                            active={m.identifier === selectedModuleId}
                                                            clickHandler={moduleSelectedCallback}
                                                            />)}
                            <FavouriteHeader active={favouritesSelected} clickHandler={showFavourites}/>
                        </CollapsibleModuleListing>
                        <ExplicitVerticalPadding height={"0.5rem"} />
                    </CentredDiv>

                    <VideoBlockCollection>
                        {extendedModuleVideos.map(v => <VideoBlock key={v.identifier} video={v} />)}
                    </VideoBlockCollection>
                    <ExplicitVerticalPadding height={"1rem"} />
                </ModulePageGridItem>
                <ModulePageGridItem />

            </ModulePageGrid>
        </BackgroundPage>
    );
}