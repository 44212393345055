import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import IModuleDetail from "../Components/IModuleDetail";
import IVideoSummary from "../Components/IVideoSummary";
import { StandardPage, Title } from "../Components/StyledComponents";
import ModuleService from "../Services/ModuleService";

const Background = styled.div`
    background-color: ${props => props.theme.main.dark};
`;

const VideoBlockCollection = styled.div`
    margin: 0 auto;
    padding: 0;
    width: 100%;
    display: grid;
    grid-template-columns: auto 35% 35% auto;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
`;

const FullWidthImage = styled.img`
    width: 100%;
`;

const NoMarginParagraph = styled.p`
    margin: 0;
    padding: 0;
`;

const SummaryParagraph = styled.p`
    margin: 1em;
    padding: 0.5em;
    background-color: white;
    width: 100%;
`;

const Card = styled.div`
    display: inline-block;
    background-color: white;
    padding: 0;
    vertical-align: top;
    
    &:nth-child(odd) {
        grid-column-start: 2;
    }
`;

const CardBody = styled.div`
    margin: 0;
    padding: 0.5em;
`;

interface IVideoBlockProps {
    video: IVideoSummary;
}
const Link = styled.a`
    color: ${props => props.theme.main.mid};
`;

interface IMaybeLinkContentProps extends React.HTMLProps<HTMLDivElement> {
    makeLink: boolean;
    link: string
}

const MaybeLinkContent = ({makeLink, link, children}: IMaybeLinkContentProps) => {
    return makeLink 
        ? (<Link href={`${link}`}>{children}</Link>)
        : (<>{children}</>)
    ;
}

const VideoBlock = ({video} : IVideoBlockProps) => {

    return (
        <Card>
            <MaybeLinkContent makeLink={video.isAccessible} link={`/play/${video.identifier}`}>
                <FullWidthImage src={video.previewUrl ?? undefined} alt={video.title} />
            </MaybeLinkContent>
            <CardBody>
                <NoMarginParagraph>{video.summary}</NoMarginParagraph>
            </CardBody>
        </Card>
    )
};

export default function ModulePage() {

    let { moduleId } = useParams<{moduleId: string}>();

    const [moduleDetails, setModuleDetails] = useState<IModuleDetail|null>(null);

    useEffect(() => {
        const callback = async() => {
            const details = await ModuleService.getService().getDetails(moduleId);
            
            setModuleDetails(details.value);
        }

        callback();
    }, [moduleId]);


    moduleDetails?.videos.sort((a,b) => a.sortPosition - b.sortPosition);

    return (
        <Background>
            <StandardPage>
                <Title>{moduleDetails?.label}</Title>
                <SummaryParagraph>{moduleDetails?.summary}</SummaryParagraph>
                <VideoBlockCollection>
                    {moduleDetails?.videos.map(v => <VideoBlock key={v.identifier} video={v} />)}
                </VideoBlockCollection>
            </StandardPage>
        </Background>
    )
}