import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ApiResponseStatus } from "../Components/ApiResponseStatus";
import IApiResponse from "../Components/IApiResponse";
import IVideoDetail from "../Components/IVideoDetail";
import VideoSection from "../Components/VideoSection";

import {BackgroundStandardPage, CentredDiv, FlexPadding, VerticalFlexContainer} from "../Components/StyledComponents";

import config from "../config.js";
import authService from "../Services/AuthenticationService";
import styled from "styled-components";
import { Button, TextInput } from "../Components/StyledFormInputs";

const NoteParagraph = styled.p`
    background-color: ${props => props.theme.error.background};
    color: ${props => props.theme.error.font};
    margin: 3rem 0;
    padding: 0.2rem;
    text-align: center;
`;

const Form = styled.form`
    display: grid;
    grid-template-columns: fit-content(50%) auto;
`;

const GridItem = styled("div")<{span?:number}>`
    grid-column: span ${props => props.span ?? 1};
    margin: 0.2rem;
`;

const GridParagraph = styled.p`
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 1.1em;
`;

const WideTextInput = styled(TextInput)`
    width: 100%;
`;

const FullHeightVerticalFlexContainer = styled(VerticalFlexContainer)`
    height: 100%;
`;

const NoAccessParagraph = () => {
    return (<CentredDiv>
        <NoteParagraph>
            You do not have access to view this resource. If you believe this is incorrect, please contact Energy Edge.
        </NoteParagraph>
    </CentredDiv>);
}

const TooManyWatchesParagraph = () => {
    return (<CentredDiv>
        <NoteParagraph>
            You have used all of your available views for this resource. Please contact Energy Edge to facilitate obtaining further access to this resource.
        </NoteParagraph>
    </CentredDiv>);
}

const MFAuthSection = ({identifier, hasPermissionCallback}:{identifier: string, hasPermissionCallback: (state: boolean) => void}) => {

    const [code, setCode] = useState("");

    const textChangeCallback = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value);
    }

    const verifyCode = async () => {
        const tokenResult = await authService.getService().getToken();

        const token = tokenResult.value;
        if (!tokenResult.isSuccess || token === null) {
            // do something that alerts the user of bad authentication
            return;
        }

        const response = await fetch(`${config.apiHost}/videos/${identifier}/verify/${code}`, {
            method: "post",
            headers: {"Authorization": `Bearer ${token}`}
        });

        if (!response.ok) {
            hasPermissionCallback(false);
        }

        const videoResult = await response.json() as IApiResponse<void>;

        if (videoResult.status === ApiResponseStatus.Success) {
            hasPermissionCallback(true);
        }
        hasPermissionCallback(false);
    }

    return(<CentredDiv centreSize={"65%"}>
        <NoteParagraph>
            Additional authorisation is required to watch this video to prove you are the owner of the account. Please check your email for an authorisation code to enter below to continue.
        </NoteParagraph>
        <Form>
            <GridItem>
                <FullHeightVerticalFlexContainer>
                    <FlexPadding />
                    <GridParagraph>Authorisation Code:</GridParagraph>
                    <FlexPadding />
                </FullHeightVerticalFlexContainer>
            </GridItem>
            <GridItem>
                <WideTextInput onChange={textChangeCallback} error={false}/>
            </GridItem>
            <GridItem span={2}>
                <CentredDiv centreSize={"40%"}>
                    <Button onClick={(e) => {e.preventDefault(); verifyCode();}}>Submit</Button>
                </CentredDiv>
            </GridItem>
        </Form>
    </CentredDiv>)
}

export default function PlaybackPage() {

    let { identifier } = useParams<{identifier: string}>();

    const [video, setVideo] = useState<IVideoDetail | null>(null);

    const [requiresMFAuth, setRequiresMFAuth] = useState(false);
    const [mfCompleted, setMfCompleted] = useState(false);
    const [mfSuccess, setMfSuccess] = useState(false);

    const [tooManyWatches, setTooManyWatches] = useState(false);
    
    const [noAccess, setNoAccess] = useState<boolean>(false);

    useEffect(() => {
        if (mfCompleted) {
            setRequiresMFAuth(false);
            setMfSuccess(true);
        }
    }, [mfCompleted]);

    useEffect(() => {

        async function getVideo(identifier: string) {

            const tokenResult = await authService.getService().getToken();

            const token = tokenResult.value;
            if (!tokenResult.isSuccess || token === null) {
                // do something that alerts the user of bad authentication
                return;
            }

            const response = await fetch(`${config.apiHost}/videos/${identifier}`, {
                headers: {"Authorization": `Bearer ${token}`}
            });

            if (!response.ok) {
                setNoAccess(true);
                return;
            }

            const videoResult = await response.json() as IApiResponse<IVideoDetail>;

            if (videoResult.status === ApiResponseStatus.Error) {
                // something about failed request
                return;
            }
            if (videoResult.status === ApiResponseStatus.Failed) {

                const errorCodes = videoResult.error?.map(e => e.code);

                if (errorCodes?.includes("video-watch-authorise")) {
                    setRequiresMFAuth(true);
                }
                else if (errorCodes?.includes("video-max-completions")) {
                    setTooManyWatches(true);
                }
                return;
            }

            setVideo(videoResult.body);
        }

        getVideo(identifier);
        
    }, [identifier, mfSuccess]);

    const hasPermissionCallback = (state: boolean) => {
        setMfCompleted(state);
    };

    return (
        <BackgroundStandardPage colour={"background"}>
            {noAccess && <NoAccessParagraph />}
            {requiresMFAuth && <MFAuthSection identifier={identifier} hasPermissionCallback={hasPermissionCallback}/>}
            {tooManyWatches && <TooManyWatchesParagraph />}
            {video !== null && !noAccess &&  <VideoSection video={video} />}
        </BackgroundStandardPage>
    );
}