import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import IVideoSummary from "../../Components/IVideoSummary";
import { StandardPage, Title } from "../../Components/StyledComponents";
import { Checkbox } from "../../Components/StyledFormInputs";

const AdminVideoPage = () => {

    const [videos, setVideos] = useState<IVideoSummary[]>([]);
    const location = useLocation();
    
    useEffect(() => {
        setVideos([{
            title: "Gas Markets - Short Term Trading Market",
            presenter: "Elliot Tonkes",
            identifier: "I1",
            summary: "S",
            tags: [],
            previewUrl: "",
            isAccessible: true,
            sortPosition: 0
        },
        {
            title: "B",
            presenter: "P",
            identifier: "I2",
            summary: "S",
            tags: [],
            previewUrl: "",
            isAccessible: false,
            sortPosition: 0
        },
        {
            title: "C",
            presenter: "P",
            identifier: "I3",
            summary: "S",
            tags: [],
            previewUrl: "",
            isAccessible: true,
            sortPosition: 0
        }]);
    }, []);

    const togglePublishVideo = async (video: IVideoSummary, publishState: boolean) => {
        // push the change to the api
        const updateVideos = videos.map(v => {
            if (v.identifier === video.identifier) {
                v.isAccessible = publishState;
            }
            return v;
        });
        setVideos(updateVideos);
    };

    return (<StandardPage>
        <Title>Video administration</Title>

        <table>
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Presenter</th>
                    <th>Publication date</th>
                    <th>Published</th>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                {videos.map(v => (
                    <tr key={v.identifier}>
                        <td><a href={`${location.pathname}/${v.identifier}`}>{v.title}</a></td>
                        <td>{v.presenter}</td>
                        <td>NA</td>
                        <td><Checkbox checked={v.isAccessible} onChange={e => togglePublishVideo(v, e.target.checked)}/></td>
                    </tr>
                ))}
            </tbody>
        </table>
    </StandardPage>);
}

export default AdminVideoPage;