import React from "react";
import styled from "styled-components";
import { FlexPadding, HorizontalFlexContainer, StandardPage } from "../Components/StyledComponents";
import AnonymousVideoSection from "../Components/AnonymousVideoSection";
import IVideoDetail from "../Components/IVideoDetail";

const Background = styled.div`
    background-color: ${props => props.theme.main.dark};
`;

const Link = styled.a`
    color: ${props => props.theme.main.mid};
`;

const ContactUs = styled.h3`
    color: ${props => props.theme.main.light};
`;

const PreviewBasePage = ({video}: {video: IVideoDetail}) => {

    // the sample has some hard coded details as it is fetched anonymously
    return (
        <Background>
            <StandardPage>
                {video !== null &&  <AnonymousVideoSection video={video} />}
                <HorizontalFlexContainer>
                    <FlexPadding />
                    <ContactUs>
                        Contact <Link href="https://energyedge.com.au/contact-us/" target="_blank">Energy Edge</Link> for information about gaining access to the entire library.
                    </ContactUs>
                    <FlexPadding />
                </HorizontalFlexContainer>
            </StandardPage>
        </Background>
    )
};

const PreviewSttmPage = () => {
    const video : IVideoDetail = {
        title: "Gas Markets - Short Term Trading Market",
        summary:"The STTM is the Short-Term Trading Market for gas instituted as a compulsory market by AEMO to manage transactions in gas coming into consumption hubs in Sydney, Brisbane and Adelaide. Suppliers provide offers and consumers bid to arrive at a settlement price for the gas commodity day ahead. In retrospect the unders-and-overs are managed through ex-post pricing and some other services. This workshop explains the mechanics of the market, describes the price dynamics and what is needed to participate in the market.",
        accessUrl:"https://player.vimeo.com/video/498815279",
        identifier: "gasmarkets-sttm",
        presenter: "Elliot Tonkes",
        hasFavourited: false,
        tags: [],
        supplementaryMaterials: []
    };

    return (
        <PreviewBasePage video={video} />
    );
};

const PreviewOverviewPage = () => {
    const video : IVideoDetail = {
        title: "Australian Energy Markets Overview",
        summary:"The Australian energy markets span the nation with a variety of fuel, environmental and power markets. This workshop describes the different markets in place, who operates them, the basic mechanics of trading in each and how they interact. We cover the difference between WA and Eastern electricity markets, the distinction between the different AEMO gas markets and the LGC, STC and potential carbon markets.",
        accessUrl:"https://player.vimeo.com/video/472086224",
        identifier: "australian-energy-markets-overview",
        presenter: "Elliot Tonkes",
        hasFavourited: false,
        tags: [],
        supplementaryMaterials: []
    };

    return (
        <PreviewBasePage video={video} />
    );
}

export {
    PreviewSttmPage,
    PreviewOverviewPage
};