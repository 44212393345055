import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from "styled-components";
import { BackgroundStandardPage } from '../Components/StyledComponents';
import  {
    Form, FormTitle, 
    InputGroup, RightInputGroup,
    Label, FlexPadding, Button, InputField, HtmlFocusEvent, ErrorList
}  from '../Components/StyledFormInputs';

import UserService from "../Services/UserService";

const RegisterForm = styled(Form)`
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
`;

export default function RegisterPage() {

    const history = useHistory();

    const [email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [pwVerify, setPwVerify] = useState("");
    const [phone, setPhone] = useState("");

    const [submitResponse, setSubmitResponse] = useState<boolean|null>(null);
    const [disableControls, setDisableControls] = useState(false);
    const [disableInputs, setDisableInputs] = useState(false);
    const [errorList, setErrorList] = useState<string[]>([]);

    const submitCallback = React.useCallback(async (e:any) => {
        e.preventDefault();

        setDisableControls(true);
        setDisableInputs(true);
        var registerResult = await UserService.getService().CreateUser(email, fullName, name, password, pwVerify, phone);
        
        if (!registerResult.isSuccess) {
            setErrorList(registerResult.messages);
            setDisableInputs(false);
            setDisableControls(false);
        }

        setSubmitResponse(registerResult.isSuccess);

    }, [email, name, password, pwVerify, fullName, phone]);

    const cancelCallback = React.useCallback((e:any) => {
        e.preventDefault();

        history.goBack();
    }, [history]);

    return (
    <BackgroundStandardPage colour={"background"}>
        <RegisterForm>
            <FormTitle>Registration form</FormTitle>
            <InputField label="Email" 
                disabled={disableControls || disableInputs}
                handler={(e : HtmlFocusEvent) => setEmail(e.target.value)} 
                validator={(e: HtmlFocusEvent) => e.target.value === ""} 
                title="Your corporate email address"
                errorLabel="Email address must be provided."/>
            <InputField label="Full Name" 
                disabled={disableControls || disableInputs}
                handler={(e : HtmlFocusEvent) => setFullName(e.target.value)} 
                validator={(e: HtmlFocusEvent) => e.target.value === ""} 
                title="Your full name"
                errorLabel="Name must be provided."/>
            <InputField label="Display Name" 
                disabled={disableControls || disableInputs}
                handler={(e : HtmlFocusEvent) => setName(e.target.value)} 
                validator={(e: HtmlFocusEvent) => e.target.value === ""} 
                title="The name the system will display for your name"
                errorLabel="Name must be provided."/>
            <InputField label="Password" 
                type="password" 
                disabled={disableControls || disableInputs}
                handler={(e : HtmlFocusEvent) => setPassword(e.target.value)} 
                validator={(e: HtmlFocusEvent) => password.length < 12} 
                title="Password must be at least 12 characters in length."
                errorLabel="Password must be at least 12 characters in length."/>
            <InputField label="Verify Password" 
                type="password" 
                disabled={disableControls || disableInputs}
                handler={(e : HtmlFocusEvent) => setPwVerify(e.target.value)} 
                validator={(e: HtmlFocusEvent) => password !== pwVerify} 
                errorLabel="Passwords must match."/>
            <InputField label="Phone" 
                disabled={disableControls || disableInputs}
                handler={(e : HtmlFocusEvent) => setPhone(e.target.value)} 
                validator={(e: HtmlFocusEvent) => e.target.value === ""} 
                title="Your contact phone number"
                errorLabel="Phone must be provided"/>
            <RightInputGroup>
                {submitResponse !== null && (submitResponse
                    ? <Label>Registration successful. Please check your email to activate your account.</Label>
                    : <ErrorList items={errorList}></ErrorList>
                )}
            </RightInputGroup>
            <InputGroup>
                <FlexPadding />
                <Button disabled={disableControls} onClick={cancelCallback}>Cancel</Button>
                <Button disabled={disableControls} onClick={submitCallback}>Register</Button>
            </InputGroup>
        </RegisterForm>
    </BackgroundStandardPage>
    )
}