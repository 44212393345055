import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { StandardPage } from "../Components/StyledComponents";
import { Button, ErrorList, FlexPadding, Form, FormTitle, HtmlFocusEvent, InputField, InputGroup } from "../Components/StyledFormInputs";
import UserService from "../Services/UserService";

const ResetPasswordForm = styled(Form)``;

const ResetPasswordPage = () => {

    const {code} = useParams<{code: string}>();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [pwVerify, setPwVerify] = useState("");

    const [disableControls, setDisableControls] = useState(false);
    const [disableInputs, setDisableInputs] = useState(false);

    const [isReset, setIsReset] = useState<boolean|null>(null);
    const [resetErrors, setResetErrors] = useState<string[]>([]);

    const resetCallback = React.useCallback(async (e) => {
        e.preventDefault();

        setDisableControls(true);
        setDisableInputs(true);
        var registerResult = await UserService.getService().ResetPassword(email, code, password, pwVerify);
        
        if (!registerResult.isSuccess) {
            setResetErrors(registerResult.messages);
            setDisableInputs(false);
            setDisableControls(false);
        }

        setIsReset(registerResult.isSuccess);
    },[email, code, password, pwVerify]);

    return (
        <StandardPage>
            <ResetPasswordForm>
                <FormTitle>Reset password</FormTitle>
                <InputField label="Email" 
                    type="text" 
                    disabled={disableControls || disableInputs}
                    handler={(e : HtmlFocusEvent) => setEmail(e.target.value)} 
                    validator={(e: HtmlFocusEvent) => email.length <= 0} 
                    errorLabel="Email address cannot be empty."/>
                <InputField label="Password" 
                    type="password" 
                    disabled={disableControls || disableInputs}
                    handler={(e : HtmlFocusEvent) => setPassword(e.target.value)} 
                    validator={(e: HtmlFocusEvent) => password.length < 12} 
                    errorLabel="Password must be at least 12 characters in length."/>
                <InputField label="Verify Password" 
                    type="password" 
                    disabled={disableControls || disableInputs}
                    handler={(e : HtmlFocusEvent) => setPwVerify(e.target.value)} 
                    validator={(e: HtmlFocusEvent) => password !== pwVerify} 
                    errorLabel="Passwords must match."/>
                <InputGroup>
                    {isReset === false && <ErrorList items={resetErrors} />}
                    {isReset === true && <p>Your password has been reset. You can now <Link to="/login">login</Link> with your new password.</p>}
                </InputGroup>
                <InputGroup>
                    <FlexPadding />
                    <Button disabled={disableControls} onClick={resetCallback}>Reset Password</Button>
                </InputGroup>
            </ResetPasswordForm>
        </StandardPage>
    );
};

export default ResetPasswordPage;