import React, { useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import styled from "styled-components";
import AcceptableUsePolicy from "../Components/AcceptableUsePolicy";
import DarkTitle from "../Components/styled/DarkTitle";
import { Form, FormLabel, FormTextInput } from "../Components/styled/Form";
import SmallDarkTitle from "../Components/styled/SmallDarkTitle";
import { BackgroundStandardPage, CentredDiv } from "../Components/StyledComponents";
import { FullWidthButton, Checkbox, ErrorListDisplay, ErrorListItem } from "../Components/StyledFormInputs";
import TermsOfService from "../Components/TermsOfService";
import AuthenticationService from "../Services/AuthenticationService";
import UserService, { ProcurementDetails } from "../Services/UserService";

export interface IGrantAppAccessPageProps {
    isLoggedIn: boolean;
    setIsLoggedIn: (state: boolean) => void;
}

const Paragraph = styled.p`
    margin: 0;
`;

export default function GrantAppAccessPage({isLoggedIn, setIsLoggedIn}: IGrantAppAccessPageProps) {

    const query = new URLSearchParams(useLocation().search);
    const returnAddress = query.get("return") ?? "/library";

    const [agreedToTermsOfService, setAgreedToTermsOfService] = useState(false);
    const [agreedToAcceptableUsePolicy, setAgreedToAcceptableUsePolicy] = useState(false);
    const [hasAccessToken, setHasAccessToken] = useState(false);

    const [procurementDetails, setProcurementDetails] = useState<ProcurementDetails>({name:"", phone:"", email:"", details:null});

    const [sending, setSending] = useState(false);

    const [submitError, setSubmitError] = useState<string|null>(null);

    const grantAccessCallback = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        setSending(true);
        setSubmitError(null);
        const grantedAccess = await AuthenticationService.getService().grantAppAccess();

        if (!grantedAccess.isSuccess) {
            console.log("grant failed");
            setSending(false);
            setSubmitError("An error occurred when processing your request. Please try again. If this persists, please contact Energy Edge at support@energyedge.com.au.");
            // do something with the errors
            return;
        }

        // trigger doing the login process
        const tokenResult = await AuthenticationService.getService().getToken();
        if (!tokenResult.isSuccess) {
            console.log("auth failed");
            setSending(false);
            setSubmitError("An error occurred when processing your request. Please try again. If this persists, please contact Energy Edge at support@energyedge.com.au.");
            // do somethign with errors
            return;
        }

        // register user with application
        const registerResult = await UserService.getService().Register(procurementDetails);
        if (!registerResult.isSuccess) {
            console.log("register failed");
            setSending(false);
            setSubmitError("An error occurred when processing your request. Please try again. If this persists, please contact Energy Edge at support@energyedge.com.au.");
            // do somethign with errors
            return;
        }

        setIsLoggedIn(true);
    };

    const handleAcceptTermsChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        setAgreedToTermsOfService(e.target.checked);
    }

    const handleAcceptUsePolicyChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        setAgreedToAcceptableUsePolicy(e.target.checked);
    }

    const handleHasAccessTokenChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        setHasAccessToken(e.target.checked);
        setProcurementDetails({name:"", phone:"", email:"", details:null});
    }

    const handleTokenFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const tokenValue = event.target.value;
        const details = {
            name: "Access Token",
            phone: "Token",
            email: "Token",
            details: tokenValue
        };
        setProcurementDetails(details as ProcurementDetails);
    };

    const handleTextFieldChange = (field: string) => {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value;
            const detailsCopy = Object.assign({}, procurementDetails) as any;
            detailsCopy[field] = newValue;
            setProcurementDetails(detailsCopy as ProcurementDetails);
        };
    };

    return (
        isLoggedIn ? <Redirect to={returnAddress} /> :
        <BackgroundStandardPage colour="background">
            <DarkTitle>Request access to Energy Training</DarkTitle>
            <SmallDarkTitle>Terms of Service</SmallDarkTitle>
            <TermsOfService />
            <SmallDarkTitle>Acceptable Use Policy</SmallDarkTitle>
            <AcceptableUsePolicy />
            <Paragraph>
                <Checkbox defaultChecked={agreedToTermsOfService} onChange={handleAcceptTermsChange}/>I accept the terms of service.
            </Paragraph>
            <Paragraph>
                <Checkbox defaultChecked={agreedToAcceptableUsePolicy} onChange={handleAcceptUsePolicyChange}/>I agree to abide by the acceptable use policy.
            </Paragraph>
            {agreedToTermsOfService && agreedToAcceptableUsePolicy && <>
                <Paragraph>
                    <Checkbox defaultChecked={hasAccessToken} onChange={handleHasAccessTokenChange}/>I have an access token.
                </Paragraph>
                {hasAccessToken && <>
                    <CentredDiv centreSize={"50%"}>
                        <Form>
                            <FormLabel>Access Token</FormLabel><FormTextInput onChange={handleTokenFieldChange}/>
                        </Form>
                    </CentredDiv>
                </>}
                {!hasAccessToken && <>
                    <SmallDarkTitle>Procurement contact details</SmallDarkTitle>
                    <CentredDiv centreSize={"50%"}>
                        <Form>
                            <FormLabel>Name</FormLabel><FormTextInput onChange={handleTextFieldChange("name")}/>
                            <FormLabel>Phone</FormLabel><FormTextInput onChange={handleTextFieldChange("phone")}/>
                            <FormLabel>Email</FormLabel><FormTextInput onChange={handleTextFieldChange("email")} />
                            <FormLabel>Details</FormLabel><FormTextInput onChange={handleTextFieldChange("details")} />
                        </Form>
                    </CentredDiv>
                </>
                }
                {submitError != null && <ErrorListDisplay><ErrorListItem>{submitError}</ErrorListItem></ErrorListDisplay>}
                <FormLabel /><FullWidthButton onClick={grantAccessCallback} disabled={sending}>{sending ? "Submitting..." : "Request access"}</FullWidthButton>
            </>}
        </BackgroundStandardPage>
    );
}