import ee, { ServiceProvider } from "@ee/ee-authentication-services";
import { ApiResponseStatus } from "../Components/ApiResponseStatus";
import IApiResponse from "../Components/IApiResponse";
import { Result } from "../Components/Result";
import config from "../config.js";
import AuthenticationService from "./AuthenticationService";

export interface IUserSummary {
    identifier: string;
    displayName: string;
    isEnabled: boolean;
}

export interface ProcurementDetails {
    name: string;
    phone:string;
    email:string;
    details:string|null;
}

export interface IUserService {
    CreateUser(email: string, fullName:string, displayName: string, password: string, passwordVerify: string, phone: string) : Promise<Result>;
    Activate(code: string) : Promise<Result>;

    Register(procurementDetails: ProcurementDetails) : Promise<Result>;
    SendGrantEmail(userId: string) : Promise<Result>;

    ForgotPassword(email: string) : Promise<Result>;
    ResetPassword(email: string, code: string, password: string, passwordVerify: string) : Promise<Result>;
}

export default class UserService implements IUserService {
    
    userService: ee.IUserService;

    constructor() {
        const sp = new ServiceProvider(config.authHost, config.applicationId);
        this.userService = sp.getUserService();
    }

    private static instance : IUserService|null = null;
    public static getService() : IUserService {
        if (this.instance === null) {
            this.instance = new UserService();
        }

        return this.instance;
    }

    public async CreateUser(email: string, fullName:string, displayName: string, password: string, passwordVerify: string, phone: string) : Promise<Result> {
        
        try {
            // do the registration

            const endpoint = `${config.apiHost}/users/create`;

            const data = {
                email: email,
                fullName: fullName,
                displayName: displayName,
                password: password,
                passwordVerify: passwordVerify,
                phone: phone
            };

            const response = await fetch(endpoint, {
                method: "post",
                headers: { 
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });
            const apiResponse = await (response.json() as Promise<IApiResponse<null>>);

            if (apiResponse.status === ApiResponseStatus.Error
                || apiResponse.status === ApiResponseStatus.Failed) {
                    const messages = apiResponse.error;
                    if (messages == null) {
                        return Result.Failed(["Registration failed for an unknown reason."]);
                    } else {
                        return Result.Failed(messages.map(e => `${e.code}: ${e.message}`) ?? []);
                    }    
            }

            return Result.Success();
        }
        catch {
            // no extra info given yet
            return Result.Failed(["Registration failed for an unknown reason."]);
        }
    }

    public async Activate(code: string) : Promise<Result> {
        const endpoint = `${config.apiHost}/users/activate/${code}`;

        try {
            const response = await fetch(endpoint, {
                "method": "POST"
            })
            .then(r => r.json() as Promise<IApiResponse<null>>);

            if (response.status === ApiResponseStatus.Success) {
                return Result.Success();
            } else {
                const messages = response.error;
                if (messages == null) {
                    return Result.Failed(["Activation failed for an unknown reason."]);
                } else {
                    return Result.Failed(messages.map(e => `${e.code}: ${e.message}`) ?? []);
                } 
            }
            
        }
        catch {
            return Result.Failed(["Activation failed for an unknown reason."]);
        }
    }

    public async Register(procurementDetails: ProcurementDetails) : Promise<Result> {
        const endpoint = `${config.apiHost}/users/register/self`;

        const result = await AuthenticationService.getService().getToken();
        if (!result.isSuccess || result.value === null) {
            return Result.Failed(result.messages);
        }

        var name: string;
        var email: string;
        try {
            const details = await this.userService.getSelfDetails();
            name = details.fullName;
            email = details.email;
        }
        catch (e) {
            return Result.Failed([e.message]);
        }

        const token = result.value;

        const requestBody = {
            name: name,
            email: email,
            procurementContact: procurementDetails
        };

        const response = await fetch(endpoint, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
        }).then(r => r.json() as Promise<IApiResponse<void>>);

        if (response.status === ApiResponseStatus.Success) {
            return Result.Success();
        } else {
            return Result.Failed(response.error?.map(e => `${e.code}: ${e.message}`) ?? []);
        }
    }

    public async SendGrantEmail(userId: string) : Promise<Result> {
        try {

            const result = await AuthenticationService.getService().getToken();
            if (!result.isSuccess || result.value === null) {
                return Result.Failed(result.messages);
            }

            const token = result.value;

            // now make the actual request
            const data = {
                userId: userId
            };
            const apiResponse = await fetch(`${config.apiHost}/users/sendGrant`, {
                method: "POST",
                headers: { 
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then(r => r.json() as Promise<IApiResponse<null>>);

            if (apiResponse.status === ApiResponseStatus.Error
                || apiResponse.status === ApiResponseStatus.Failed) {
                const messages = apiResponse.error;
                if (messages == null) {
                    return Result.Failed(["Sending email advising of new permissions failed for an unknown reason."]);
                } else {
                    return Result.Failed(messages.map(e => `${e.code}: ${e.message}`) ?? []);
                }  
            }

            return Result.Success();
        }
        catch (reason) {
            return Result.Failed(["Could not send email to advise user of new permission"]);
        }
    }

    public async ForgotPassword(email: string) : Promise<Result> {
        try {

            // now make the actual request
            const data = {
                email: email
            };
            const apiResponse = await fetch(`${config.apiHost}/users/forgot`, {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then(r => r.json() as Promise<IApiResponse<null>>);

            if (apiResponse.status === ApiResponseStatus.Error
                || apiResponse.status === ApiResponseStatus.Failed) {
                const messages = apiResponse.error;
                if (messages == null) {
                    return Result.Failed(["Forgot password failed for an unknown reason."]);
                } else {
                    return Result.Failed(messages.map(e => `${e.code}: ${e.message}`) ?? []);
                }  
            }

            return Result.Success();
        }
        catch (reason) {
            return Result.Failed(["Could not initiate forgot password process for an unknown reason."]);
        }
    }

    public async ResetPassword(email: string, code: string, password: string, passwordVerify: string) : Promise<Result> {
        try {
            

            // now make the actual request
            const data = {
                email: email,
                token: code,
                newPassword: password,
                newPasswordVerify: passwordVerify
            };
            const apiResponse = await fetch(`${config.apiHost}/users/reset`, {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then(r => r.json() as Promise<IApiResponse<null>>);

            if (apiResponse.status === ApiResponseStatus.Error
                || apiResponse.status === ApiResponseStatus.Failed) {
                    const messages = apiResponse.error;
                    if (messages == null) {
                        return Result.Failed(["Reset password request failed for an unknown reason."]);
                    } else {
                        return Result.Failed(messages.map(e => `${e.code}: ${e.message}`) ?? []);
                    } 
            }

            return Result.Success();
        }
        catch (reason) {
            return Result.Failed(["Could not reset password for an unknown reason."]);
        }
    }
}