
import styled from "styled-components";

const Paragraph = styled.p`
    color: ${props => props.theme.main.dark};
    text-align: center;
`;

const TermsOfService = () =>
    <>
    <Paragraph>
        The terms of service are available online and can be <a href="/media/EnergyEdge-AEMMasterclass-TermsOfService.pdf">downloaded here</a>.
    </Paragraph>
</>;

export default TermsOfService;