import { AppUserDetailModel, ServiceProvider } from "@ee/ee-authentication-services";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { StandardPage, Title } from "../Components/StyledComponents";
import { Button } from "../Components/StyledFormInputs";
import config from "../config";
import UserService from "../Services/UserService";



const Background = styled.div`
    background-color: ${props => props.theme.main.dark};
    color: white;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: auto 20% 40% auto;
    width: 100%;
`;
const GridCell = styled.div``;
const GridCellRowStart = styled(GridCell)`
    grid-column-start: 2;
`;

const AppUserDisplay = ({user}:{user:AppUserDetailModel}) => {

    return (<Grid>
        <GridCellRowStart>Name</GridCellRowStart><GridCell>{user.name}</GridCell>
    </Grid>);
}

const sp = new ServiceProvider(config.authHost, config.applicationId);

export default function AccessResponsePage() {

    const { userId } = useParams<{userId: string}>();
    const [processing, setProcessing] = useState(false);
    const [userDetails, setUserDetails] = useState<AppUserDetailModel|null>(null);
    const [messages, setMessages] = useState<string[]>([]);

    useEffect(() => {
        const userDetailsCallback = async() => {
            const appService = sp.getApplicationService();

            try {
                const details = await appService.getUserDetails(userId);
                setUserDetails(details);
            } catch (e) {
                if (e.hasOwnProperty("messages")) {
                    setMessages(e.messages);
                } else {
                    setMessages(["An error occurred: " + e.message]);
                }
            }
            
        };

        userDetailsCallback();
    },[userId]);

    const grantCallback = async () => {
        const appService = sp.getApplicationService();
        
        setProcessing(true);
        setMessages(["Trying to grant permission"]);
        try {
            await appService.updateGroupUser("viewer", userId, true);
            setMessages(["Permission granted successfully", "Trying to send email"]);
            try {
                await UserService.getService().SendGrantEmail(userId);
                setMessages(["Permission granted successfully", "Email sent successfully"]);
            } catch (e) {
                if (e.hasOwnProperty("messages")) {
                    setMessages(["Permission granted successfully"].concat(e.messages));
                } else {
                    setMessages(["An error occurred: " + e.message]);
                }
            }
        } catch (e) {
            if (e.hasOwnProperty("messages")) {
                setMessages(e.messages);
            } else {
                setMessages(["An error occurred: " + e.message]);
            }
        }
        setProcessing(false);
    }

    return(<Background>
        <StandardPage>
            <Title>Grant user access</Title>
            {userDetails === null && <p>Loading user details</p>}
            {userDetails !== null && <AppUserDisplay user={userDetails} />}
            <Button disabled={processing} onClick={(e) => {e.preventDefault(); grantCallback();}}>Grant access</Button>
            <ul>
                {messages.map(m => (<li>{m}</li>))}
            </ul>
        </StandardPage>
    </Background>)
}