import React from 'react';
import styled from "styled-components";

import CentredDiv from './styled/CentredDiv';

const StyledStandardPage = styled.div`
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @media (max-width: 750px) {
        width: calc(100% - 10px);
        padding: 5px;
        margin: 0;
    }

`;

const StyledBackgroundPage = styled("div")<{colour: string}>`
    position: relative;
    width: 100%;
    height: 100%;

    background-color: ${props => props.theme.main[props.colour]};
    background-size: cover;
`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;

    margin: 0;
    padding: 0;
`;

const HorizontalFlexContainer = styled(FlexContainer)`
    width: 100%;
    flex-direction: row;
`;
const VerticalFlexContainer = styled(FlexContainer)`
    flex-direction: column;
    height:
`;

const FlexPadding = styled.div`
    flex: 1;
`;

const Title = styled.h1`
    color: ${props => props.theme.main.light};
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 750px) {
        width: 100%;
    }
`;

const FloatRightContainer = styled.div`
    float: right;
    flex-direction: row;
    display: flex;
`;

type DivProps = React.HTMLProps<HTMLDivElement>;

const FullWidthDiv = styled.div`
    width: 100%;
`;

export interface IStandardPageProps extends DivProps {
    flexDirection? : string;
}

const StandardPage = ({children, flexDirection} : IStandardPageProps) => {
    return (
        <CentredDiv centreSize={"90%"}>
            <StyledStandardPage>
                {
                    (flexDirection ?? "row") === "row"
                        ? <>{children}</>
                        : <FullWidthDiv><VerticalFlexContainer>{children}</VerticalFlexContainer></FullWidthDiv>}
            </StyledStandardPage>
        </CentredDiv>
    );
}

export interface IBackgroundPageProps extends DivProps {
    colour: string;
}

const BackgroundPage  = ({children, colour} : IBackgroundPageProps) => 
    <StyledBackgroundPage colour={colour}>
        {children}
    </StyledBackgroundPage>;

const BackgroundStandardPage = ({children, colour} : IBackgroundPageProps) => {
    return (
        <StyledBackgroundPage colour={colour}>
            <StandardPage>
                {children}
            </StandardPage>
        </StyledBackgroundPage>
    );
};



export {
    Title,
    CentredDiv,
    BackgroundPage,
    StandardPage,
    BackgroundStandardPage,
    HorizontalFlexContainer,
    VerticalFlexContainer,
    FlexPadding,
    FloatRightContainer
};