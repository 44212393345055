import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { ThemeProvider } from 'styled-components';

import theme from './theme';
import auth from "./Services/AuthenticationService";

import AdminRoute from './Components/AdminRoute';
import PrivateRoute from './Components/PrivateRoute';
import HomePage from './Pages/HomePage';
import LoginPage from './Pages/LoginPage';
import LogoutPage from './Pages/LogoutPage';
import PlaybackPage from './Pages/PlaybackPage';
import RegisterPage from './Pages/RegisterPage';
import ActivatePage from './Pages/ActivatePage';
import ForgotPasswordPage from './Pages/ForgotPasswordPage';
import ResetPasswordPage from './Pages/ResetPasswordPage';
import Header from './Components/Header';
import AdminPage from './Pages/AdminPage';
import MediaPage from './Pages/MediaPage';
import AdminVideoPage from './Pages/Admin/AdminVideoPage';
import { PreviewOverviewPage, PreviewSttmPage } from './Pages/PreviewPage';
import ModulePage from './Pages/ModulePage';
import GrantAppAccessPage from './Pages/GrantAppAccessPage';
import AccessResponsePage from './Pages/AccessResponsePage';
import ModuleLibraryPage from './Pages/ModuleLibraryPage';
import Footer from './Components/Footer';
import ContentPage from './Pages/ContentPage';
import PricingPage from './Pages/PricingPage';
import FullWindowHeightPageWrapper from './Components/styled/FullWindowHeightPageWrapper';
import { FlexPadding } from './Components/StyledComponents';
import TermsPage from './Pages/TermsPage';

function App() {

  const authService = auth.getService();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
    useEffect(() => {
        authService.canGetValidToken()
          .then(v => {
            setIsLoggedIn(v); // overwrite for already authenticated people
            return authService.isAdmin();
          })
          .then(r => {
            setIsAdmin(r.isSuccess); // overwrite for authenticated administrators
          });
    }, [authService]);

  return (
    <ThemeProvider theme={theme}>
      <FullWindowHeightPageWrapper>
        <Header isLoggedIn={isLoggedIn} isAdmin={isAdmin}/>
        <Router>
          <Switch>
            <Route path="/admin/videos" component={AdminVideoPage} />
            <Route path="/" component={HomePage} exact />
            <Route path="/content" component={ContentPage} />
            <Route path="/pricing" component={PricingPage} />
            <Route path="/terms" component={TermsPage} />
            <Route path="/login" render={(props) => <LoginPage isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/grant" render={(props) => <GrantAppAccessPage isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/register" component={RegisterPage} />
            <PrivateRoute path="/access/:userId" component={AccessResponsePage} exact />
            <Route path="/activate/:code" component={ActivatePage} exact/>
            <Route path="/forgot" component={ForgotPasswordPage} exact />
            <Route path="/reset/:code" component={ResetPasswordPage} exact/>
            <Route path="/logout" render={(props) => <LogoutPage isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />}/>
            <PrivateRoute path="/library" component={ModuleLibraryPage} exact/>
            <Route path="/modules/:moduleId" component={ModulePage} exact/>
            <PrivateRoute path="/play/:identifier" component={PlaybackPage} exact/>
            <PrivateRoute path="/media/:mediaId" component={MediaPage} exact/>
            <Route path="/preview" component={PreviewOverviewPage} exact />
            <Route path="/preview/sttm" component={PreviewSttmPage} exact />
            <AdminRoute path="/admin" component={AdminPage} />
          </Switch>
        </Router>
        <FlexPadding />
        <Footer />
      </FullWindowHeightPageWrapper>
    </ThemeProvider>
  );
}

export default App;
