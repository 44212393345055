import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BreadCrumbs, Crumb } from "../../Components/BreadCrumbs";
import { FlexPadding, HorizontalFlexContainer, StandardPage, VerticalFlexContainer } from "../../Components/StyledComponents";
import { Button } from "../../Components/StyledFormInputs";
import { ClientService, IClientSummary } from "../../Services/ClientService";

const StyledClientSummaryRow = styled.tr`
    background-color: #DDD;
    &.highlight {
        background-color: #CCC;
    }
    &:hover {
        background-color: #808080;
        color: white;
    }
    &.enabled {
        background-color: #C3FDB1;
    }
    &.enabled.highlight {
        background-color: #93FF72;
    }
    &.enabled:hover {
        background-color: #009900;
    }

    & td {
        width: 100%;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid black;
    }

    & a {
        color: #000;
        text-decoration: none;
        display: block;
        padding: 5px;
    }
    & a:hover {
        color: white;
    }
`;

const ClientSummaryTable = styled.table`
    width: 100%;
    border-spacing: 0;
`;

const Input = styled.input`
    font-size: 1.1em;
    padding: 0.2em;
`;

interface IClientSummaryRowProps extends React.HTMLProps<HTMLTableRowElement> {
    highlight: boolean;
    isEnabled: boolean;
}

const ClientSummaryRow = ({children, highlight, isEnabled}:IClientSummaryRowProps) => {
    return (
        <StyledClientSummaryRow className={(highlight ? "highlight" : "") + " " + (isEnabled ? "enabled" : "")}>
            {children}
        </StyledClientSummaryRow>
    );
};

const NewClientHandler = () => {

    const clientService = ClientService.getService();

    const [newClient, setNewClient] = useState("");

    const newClientCallback = async (e: any) => {
        e.preventDefault();

        const result = await clientService.addNewClient(newClient);

        if (result.isSuccess) {
            console.log("added client")
            setNewClient("");

            // force page reload
            window.location.reload();
        } else {
            console.log(result.messages);
        }
    }

    const changeCallback = (e:any) => {
        setNewClient(e.target.value);
    };

    return (<HorizontalFlexContainer>
        <FlexPadding />
        <VerticalFlexContainer>
            <FlexPadding />
            <div>
                <span>Add new client: </span>
                <Input value={newClient} onChange={changeCallback} />
            </div>
            <FlexPadding />
        </VerticalFlexContainer>
        <Button onClick={newClientCallback} disabled={newClient===""}>Add</Button>
    </HorizontalFlexContainer>);
}

const AdminClient = () => {
    const clientService = ClientService.getService();

    const [clients, setClients] = useState<IClientSummary[]>([]);

    useEffect(() => {

        async function getClients(): Promise<void> {
            const result = await clientService.getSummaries();

            if (result.isSuccess && result.value !== null) {
                const clients = result.value;
                clients.sort((a,b) => {
                    if (a.name < b.name) { return -1;}
                    if (a.name > b.name) { return 1;}
                    return 0;
                });
                setClients(clients);
            } else {
                console.log(result.messages);
            }
        }

        getClients();

    }, [clientService]);

    return (
        <StandardPage>
            <BreadCrumbs>
                <Crumb label="Admin" href="/admin" />
                <Crumb label="Clients" href="/admin/clients" />
            </BreadCrumbs>
            <ClientSummaryTable>
                <thead>
                    <tr>
                        <th>Client name</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        clients.map((c,i) => (
                            <ClientSummaryRow key={c.code} highlight={i%2===0} isEnabled={c.isEnabled}>
                                <td><a href={`/admin/clients/${c.code}`}>{c.name}</a></td>
                            </ClientSummaryRow>
                        ))
                    }       
                </tbody>
            </ClientSummaryTable>
            <NewClientHandler />
        </StandardPage>
    );
}

export default AdminClient;