import  React, { useEffect, useState } from  "react";
import { Route, Redirect } from  "react-router-dom";

import authService from "../Services/AuthenticationService";



const AdminRoute : React.FC<{
    component: React.FC;
    path: string;
    exact?: boolean
}> = (props) => {

    const [isLoggedIn, setIsLoggedIn] = useState<boolean|null>(null);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    
    useEffect(() => {
        const a = authService.getService();
        a.canGetValidToken()
            .then(async v => {
                if (v) {
                    const r = await a.isAdmin();
                    setIsAdmin(r.isSuccess);
                    setIsLoggedIn(true);
                } else {
                    setIsLoggedIn(false);
                }
            });
    }, [])

    return  isLoggedIn === null ? (null) : (
                isLoggedIn
                ? (
                    isAdmin
                    ? (<Route  path={props.path}  exact={props.exact} component={props.component} />)
                    : (<Redirect  to={`/library`}  />)
                )
                : (<Redirect  to={`/login?return=${window.location.pathname}`}  />)
            );
}

export default AdminRoute;